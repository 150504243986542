import React from 'react';

import InfoTooltip from '../InfoTooltip';
import Markdown from '../Markdown.js';

const defaultClass = 'ncss-label';

const Label = ({ children, title, className, useBareClass, htmlFor, ...props }) => (
  <label
    {...props}
    className={`${useBareClass || defaultClass} ${className || ''}`}
    htmlFor={`${htmlFor}`}
    id="label"
    key="label"
  >
    {children}
    {title ? (
      <InfoTooltip key="tooltip" className="ml2-sm ">
        <div className="tooltip-markdown">
          <Markdown source={title} />
        </div>
      </InfoTooltip>
    ) : null}
  </label>
);

export default Label;
