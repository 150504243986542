import { createBrowserHistory } from 'history';
import qs from 'qs';
const history = createBrowserHistory();
let location = history.location;

function addLocationQuery(location) {
  if (!location.search) return;
  let query ={...{query: qs.parse(location.search.substring(1))}}
  location = {...(location),...query};
}

addLocationQuery(location);

history.listen(() => {
  addLocationQuery(history);
});

export default history;
