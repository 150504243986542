import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import auth from './authReducer.js';

import navigation from './navigationReducer.js';
import offline from './offlineReducer.js';
import listNotification from './listNotificationReducer.js';
import gridMaterialRowData from './materialRowDataReducer';
import gridColorSearch from './colorGridSearchReducer'
import gridVendorSearch from './vendorSearchReducer.js'; 
import colorGridData from './colorGridDataReducer';
import vendorGridData from './vendorGridDataReducer';
import getDashboardCountData from './dashboardCountReducer'
import gridVendorFetch from './vendorFetchReducer.js';
import userDetails from './userDetailsReducer.js';
import groupListData from './groupListReducer.js'; 
import gridLocationSearch from './locationSearchReducers.js';
import materialVendorSearch from './materialVendorSearchReducer.js';
import materialLocSearch from './materialGridSearchByLocReducer';
import vendorDetails from './vendorDetailsReducer.js';
import editVendorMapping from './editVendorMappingReducer.js';
import editMaterialMapping from './editMaterialMappingReducer.js';
import colorMappingGridDataReducer from './colorMappingGridDataReducer.js';
import uploadGrid from './uploadReducer.js';
import uploadedFilesList from './uploadedFilesListReducer.js';
import fileDetailsData from './fileDetailsReducer.js';
import poGrid from './poReducer.js';
import poHeaderGrid from './poHeaderReducer.js';
import poItemGrid from './poItemReducer.js';
import poMappingGrid from './poMappingTableReducer.js';
import gridPoVendorSearch from './poVendorSearchReducer.js';
import gridPoUserDetails from './poUserDetailsReducer.js';
import idLockerUsersAccessReducer from './idLockerUsersAccessReducer.js'
import idLockerUsersReducer from './idLockerUsersReducer.js'
import idLockerUserAccessListReducer from './idLockerUserAccessListReducer.js'
import idLockerRequestListReducer from './idLockerRequestListReducer.js'
const rootReducer = combineReducers({
  routing,
  auth,
  navigation,
  offline,
  listNotification,
  gridMaterialRowData,
  gridColorSearch,
  gridVendorSearch,
  colorGridData,
  vendorGridData,
  getDashboardCountData,
  gridVendorFetch,
  userDetails,
  groupListData,
  gridLocationSearch,
  materialVendorSearch,
  materialLocSearch,
  vendorDetails,
  uploadGrid,
  editVendorMapping,
  editMaterialMapping,
  colorMappingGridDataReducer,
  uploadedFilesList,
  fileDetailsData,
  poGrid,
  poHeaderGrid,
  poItemGrid,
  poMappingGrid,
  gridPoVendorSearch,
  gridPoUserDetails,
  idLockerUsersAccessReducer,
  idLockerUsersReducer,
  idLockerUserAccessListReducer,
  idLockerRequestListReducer,
  form: formReducer
});

export default rootReducer;