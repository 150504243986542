import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call, select, take } from 'redux-saga/effects';
import { selectEmail, selectFullName, selectGroup } from '../reducers/authReducer.js';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    const email = yield select(selectEmail);
    const userName = yield select(selectFullName)
    const userType =  yield select(selectGroup)

    let options = {};
    options.email = email || action.data.email;
    options.userName = userName || action.data.userName;
    options.userType = userType || action.data.userType;

    const res = yield call(postRequest, apiConfig.createProfile.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);

      if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')(response));
        yield put(createServiceAction('getUserProfile', 'requested')());
        yield take(createServiceAction('getUserProfile', 'success').type);
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* createProfileSagas() {
  const serviceType = 'createProfile';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default createProfileSagas;