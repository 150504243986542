import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PDFObject  from './pdfView/pdfView.js';


class PdfViewer extends Component {
  componentDidMount() {
    const { fileName, containerId } = this.props;
    if(fileName)
    {
    PDFObject.embed(fileName, `#${containerId}`);
    }
  }


  render() {
    const { width, height, containerId } = this.props;
    return (
  <div style={{ width, height }} id={containerId} />
    )
  }
}
PdfViewer.propTypes = {
  fileName: PropTypes.string
};
PdfViewer.defaultProps = {
  width: '100%',
  height: '100%'
};

export default PdfViewer;
