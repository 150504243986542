import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { displayMessage, getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call} from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(getRequest, apiConfig.getIdLockerAccesses.endPointUrl);
    // Display HTTP status error message
    
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){ 
      
      const response = yield res.json();
     
     // Display server error message
     const serverErrorMessage = yield call(getServerValidationMessage, response);
     if (!serverErrorMessage && response.statusCode === 200) {
       yield put(createServiceAction(serviceType, 'success')(response));
     }
     else {
       yield put(createServiceAction(serviceType, 'error')())
       if (response?.statusCode !== 400 && response?.statusCode !== 200) {
         const message = response.message ? `${response.message}` : `${response.msg.message}`;
         yield call(displayMessage, 'error', message, 5000);
       }
     }
    }
    else{
      yield put(createServiceAction(serviceType, 'error')())
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* getIdLockerUsersAccessSagas() {
  const serviceType = 'getIdLockerUsersAccess';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default getIdLockerUsersAccessSagas;
