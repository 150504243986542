
import React, { useEffect, useState, useCallback, Suspense } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import App from "../../root/App";
import { setUserLogin, setUserLogout, authCheckRequested } from '../../actions/authActions.js';
import { loginAuthFinish, logout, validUser, validGroup, isPartner, isMdoAdmin, isVendorManager, isPartnerSA } from '../../reducers/authReducer.js';
import { getUserGroup, getFullToken } from './authHelpers'
import { Spinner, Container, H1, Button, NotFound } from '../../components/index.js';
import { InvalidRoute } from '../../components/invalid_route/InvalidRoute.js';
import LogoutUser from '../../views/auth/logoutUser.js';
import UserNotExistsError from '../../views/Generic/UserNotExistsError.js';
import UserMismatchError from '../../views/Generic/UserMismatchError.js'

const lazyRetry = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
    );
    // try to import the component
    componentImport().then((component) => {
      window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // going to refresh
        return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
};

const MdoAdmin = React.lazy (() => lazyRetry(()=> import('../mdoAdmin/mdoAdmin'), "admin") )
const Dashboard = React.lazy (() => lazyRetry(()=> import('../../views/dashboard/Dashboard.js'), "dashboard"))
const UploadDashboard = React.lazy (() => lazyRetry(()=> import('../uploadGrid/UploadDashboard'), "upload"))
const MappingData = React.lazy (() => lazyRetry(()=> import('../MappingData/MappingData'), "mapping"))
const EditColor = React.lazy(() => lazyRetry(()=> import('../editColor/EditColor'), "color"))
const SearchVendor = React.lazy(() => lazyRetry(()=> import('../searchVendor/SearchVendor'), "vendor"))
const EditDetails = React.lazy(() => lazyRetry(()=> import('../editDetails/EditDetails'), "material"))
const PoShipmentView = React.lazy (() => lazyRetry(()=> import('../Shipment/PoShipmentView'), "shipment"))
const ShipmentDetailView = React.lazy (() => lazyRetry(()=> import('../Shipment/ShipmentDetailView'), "shipmentDetailView"))

const ProtectedRoute = ({ path, children }) => {
  const { isPartnerUser, isMdoAdminUser, isVendorManagerUser, isPartnerSAUser,userValid,groupValid } = useSelector(state => ({
    isPartnerUser: isPartner(state),
    isMdoAdminUser: isMdoAdmin(state),
    isVendorManagerUser: isVendorManager(state),
    isPartnerSAUser: isPartnerSA(state),
    userValid: validUser(state),
    groupValid: validGroup(state)
  }));

  if ((!userValid && ["/error"]?.includes(path)) || 
    (!groupValid && ["/error-mismatch"]?.includes(path)) ||
    (isMdoAdminUser && ["/mdo-admin"]?.includes(path)) ||
    ((isPartnerUser || isMdoAdminUser) && ["/editColor", "/editMaterial", "/po-shipment", "/shipment-detail"]?.includes(path)) ||
    ((isVendorManagerUser || isMdoAdminUser )&& ["/editVendor"]?.includes(path)) ||
    ((isPartnerSAUser || isMdoAdminUser) && ["/editColor", "/editMaterial", "/upload"]?.includes(path))) return children;
  return <div className='restrict-user'><NotFound /></div>;
};

export const routes = [
  {
    path: '/',
    title: 'Dashboard',
    component: () => <Dashboard />,
    nav: true,
    icon: 'home',
    heading: null,
    id: 3
  },
  {
    path: '/upload',
    title: 'Upload Data',
    component: () => <ProtectedRoute path="/upload" ><UploadDashboard /></ProtectedRoute>,
    nav: true,
    icon: 'upload',
    heading: null,
    id: 9
  },
  {
    path: '/mapping-data',
    title: 'Mapping Data',
    component: () => <MappingData />,
    nav: true,
    icon: 'user-edit',
    heading: null,
    id: 21
  },
  {
    path: "/po-shipment",
    title: "PO Shipment",
    component: () => <ProtectedRoute path={"/po-shipment"} ><PoShipmentView /></ProtectedRoute>,
    nav: true,
    icon: 'user-edit',
    heading: null,
    id: 21
  },
  {
    path: "/shipment-detail",
    title: "Shipment Detail",
    component: () => <ProtectedRoute path={"/shipment-detail"} ><ShipmentDetailView /></ProtectedRoute>,
    nav: true,
    icon: 'user-edit',
    heading: null,
    id: 21
  },
  {
    path: '/editMaterial',
    title: 'Edit Details',
    component: () => <ProtectedRoute path="/editMaterial" ><EditDetails /></ProtectedRoute>,
    nav: false,
    icon: 'edit',
    heading: null,
    id: 4
  },
  {
    path: '/editColor',
    title: 'Edit Color Details',
    component: () => <ProtectedRoute path="/editColor"><EditColor /></ProtectedRoute>,
    nav: false,
    icon: 'edit',
    heading: null,
    id: 4
  },
  {
    path: '/editVendor',
    title: 'Vendor Search',
    component: () => <ProtectedRoute path="/editVendor"><SearchVendor /></ProtectedRoute>,
    nav: false,
    icon: 'search',
    heading: null,
    id: 5
  },
  {
    path: '/mdo-admin',
    title: 'Admin',
    component: () => <ProtectedRoute path="/mdo-admin" ><MdoAdmin /></ProtectedRoute>,
    nav: true,
    icon: 'user-shield',
    heading: null,
    id: 6
  },
  // {
  //   path: '/notification',
  //   title: 'Create Notification',
  //   component: CreateNotifications,
  //   nav: true,
  //   icon: 'bell',
  //   heading: null,
  //   id: 20
  // },
  {
    path: '/logout',
    title: 'Logout',
    component: () => <LogoutUser />,
    nav: false,
    icon: 'sign-out-alt',
    heading: '',
    id: 12
  },

  // Routes starting with '/error' will not have access to Nav bar
  {
    path: '/error',
    title: 'Authentication Issues',
    component: () => <ProtectedRoute path="/error" ><UserNotExistsError /></ProtectedRoute>,
    nav: false,
    heading: null,
    id: 18
  },
  {
    path: '/error-mismatch',
    title: 'Authentication Issues',
    component: () => <ProtectedRoute path="/error-mismatch" ><UserMismatchError /></ProtectedRoute>,
    nav: false,
    heading: null,
    id: 18
  },
  { component: () => <InvalidRoute />, id: 20 },
];

export const navRoutes = routes ? routes.filter(r => r.nav) : [];
const Login = () => {
  const { loginAuth, logoutStatus, userValid, groupValid } = useSelector(state => ({
    loginAuth: loginAuthFinish(state),
    logoutStatus: logout(state),
    userValid: validUser(state),
    groupValid: validGroup(state)
  }));
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState(null);
  const [authStates, setAuthStates] = useState(null);
  const [isIntial, setIsInitial] = useState(false)
  const [currentExpireTime,setCurrentExpireTime] = useState(null)

  const  checkUser = useCallback(async() =>{
  
    const tokens = await oktaAuth.tokenManager.getTokens();
    const getAccessToken = tokens.accessToken;

  if(sessionStorage.getItem("token-satus")!== "expired"){
    dispatch(authCheckRequested());
  
  
if (authState && authState.isAuthenticated && userInfo === null ) {
 
  setAuthStates(authState);
  let userInfo = await oktaAuth.token.getUserInfo();

        if (getAccessToken && getAccessToken.expiresAt && !logoutStatus) {
          authState.expiresAt = getAccessToken.expiresAt
          //this.setState({currentExpireTime:getAccessToken.expiresAt})
          setCurrentExpireTime(getAccessToken.expiresAt)
        }
        if (userInfo) {
          userInfo.group = getUserGroup(userInfo)
          let tokenInfo = null
          if (!logoutStatus) {
            tokenInfo = getFullToken(authState, userInfo, getAccessToken)
          } else {
            tokenInfo = null
          }
          if (authState) {
            dispatch(setUserLogin(tokenInfo))
          }
          setUserInfo(userInfo)
          setAuthStates(authStates)
        }
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userInfo, authState, dispatch, oktaAuth, logoutStatus]
  );

  // const login= async() => {
  //   await oktaAuth.signInWithRedirect("/login");
  // }
  // const  logoutApp = async() => {
  //   await oktaAuth.signOut({ postLogoutRedirectUri: window.location.orign + '/login' });
  // }

  const loginFlagChange = async () => {
    //const {  oktaAuth } = this.props;
    //this.setState({userInfo:null})
    setUserInfo(null)
    await oktaAuth.signInWithRedirect();
    dispatch(setUserLogout(false))
    sessionStorage.setItem("token-satus", "login-again");
    checkUser();
  }

  useEffect(() => {
    loginAuth && setIsInitial(true)
    !loginAuth && checkUser()
  }, [checkUser,loginAuth])

  useEffect(() => {
    async function requestAuthCheck() {
      const tokens = await oktaAuth.tokenManager.getTokens();
      const accessToken = tokens.accessToken;
      if (currentExpireTime && accessToken && accessToken.expiresAt && accessToken.expiresAt !== currentExpireTime && sessionStorage.getItem("token-satus") !== "expired") {
        let tokenInfo = null
        setAuthStates(authState);
        let userInfo = await oktaAuth.token.getUserInfo();
        userInfo.group = getUserGroup(userInfo)
        tokenInfo = getFullToken(authStates)
        if (authStates) {
          dispatch(setUserLogin(tokenInfo))
        }
        setCurrentExpireTime(accessToken.expiresAt)
      }
    }
    requestAuthCheck()
  }, [authStates, currentExpireTime, authState, oktaAuth, dispatch, checkUser])

  useEffect(() => {
    async function tokenExpireCheck() {
      if (!logoutStatus && sessionStorage.getItem("token-satus") !== "expired") {
        await dispatch(authCheckRequested())
      }
    }
    tokenExpireCheck()
  }, [dispatch, logoutStatus])

  if (authState && authState.isPending) {
    return <Spinner large />;
  } else if ((userInfo && !userInfo.group)) {
    return <UserNotExistsError />;
  }
  else if (!groupValid) {
    return <UserMismatchError />;
  }
  else if (!userValid) {
    return (<UserNotExistsError />)
  }
  return authState && authState.isAuthenticated && sessionStorage.getItem("token-satus") !== "expired" ?
    (<div>
      {(userInfo !== null && (loginAuth || isIntial)) ? <App userInfo={userInfo} navRoutes={navRoutes} >
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {routes.map(route => (
              <Route key={route.id} path={route.path} exact render={route.component} />
            ))}
          </Switch>
        </Suspense>
      </App> : <Spinner large />}
    </div>)
    :
    (<div>
      {/* {this.logout()}   */}
      <div className="page-align-items">
        <Container fluid>
          <H1 className="u-sm-ib u-va-m">You must be logged in to view this page</H1>
          <Button extraClassName="m2-sm u-sm-ib u-va-m" onClick={loginFlagChange} >
            Login
          </Button>
        </Container>
      </div>
    </div>)
}
export { Login };
export default Login;