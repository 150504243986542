import { groupBy } from "lodash";
import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';

const defaultState = {
  userName: null,
  email: null
};

export default function gridPoUserDetails(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getPOUserDetails', 'requested').type:
      return { ...state, ...defaultState };
    case createServiceAction('getPOUserDetails', 'success').type:
      const gridData = get(action.data, 'data', defaultState)
      return { ...state, ...{ 
        userName: new Set(gridData?.user?.map(x => x.name)), 
        email: new Set(gridData?.email),
        idByUserName: groupBy(gridData?.user, "name") } };
    case createServiceAction('getPOUserDetails', 'error').type:
      return { ...state, ...{ userName: [], email: [] } };
    default:
      return state;
  }
}
