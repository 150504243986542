import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ children, fluid, section, extraClassName, className, style }) => {
  const containerClass = [className || 'ncss-container'];
  if (extraClassName) containerClass.push(extraClassName);
  if (fluid) containerClass.push('fixed-fluid');
  if (section) containerClass.push('bg-white border-light-grey p6-sm mb5-sm');

  return (
    <div className={containerClass.join(' ')} style={style}>
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fluid: PropTypes.bool,
  section: PropTypes.bool,
  extraClassName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.string
};

export default Container;
