import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { navMenuToggle, navMenuclose } from '../../actions/navigationActions.js';
import { clearArchive } from '../../actions/archiveActions.js'
import { selectIsMenuOpen } from '../../reducers/navigationReducer.js';
import { NikeApp } from "@nike/nike-design-system-icons";
import * as NavBarConfig from "@nike/eds/dist/components/VerticalNavbar/VerticalNavbar.js";
import { selectedButton } from '../../actions/authActions.js';
import { isMdoAdmin, isPartner, isPartnerSA, isVendorManager } from '../../reducers/authReducer.js';

const SlideMenuContainer = (props) => {
  const { children,
    isPartnerSA,
    isPartner,
    isVendorManager } = props;
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const elements = () => {
    return [
      {
        id: "1",
        icon: "Home",
        label: "Dashboard",
        active: pathname === "/",
        subItems: [
        ],
        path: '/',
        hideByRoles: false
      },
      {
        id: "2",
        icon: "ProfileSwoosh",
        label: "Mapping Data",
        active: pathname === "/mapping-data",
        subItems: [
        ],
        path: '/mapping-data',
        hideByRoles: false
      },
      {
        id: "6",
        icon: "Orders",
        label: "PO Shipment",
        active: ["/po-shipment", "/shipment-detail"].includes(pathname),
        subItems: [
        ],
        path: "/po-shipment",
        hideByRoles: isPartnerSA || isVendorManager
      },
      {
        id: "3",
        icon: "Upload",
        label: "Upload",
        active: pathname === "/upload",
        subItems: [
        ],
        path: '/upload',
        hideByRoles: (isVendorManager || isPartner)
      },
      {
        id: "4",
        icon: "Personalization",
        label: "Admin",
        active: pathname === "/mdo-admin",
        subItems: [
        ],
        path: '/mdo-admin',
        hideByRoles: (isVendorManager || isPartner || isPartnerSA)
      },
      {
        id: "5",
        icon: 'QuestionCircle',
        label: "Contact Us",
        active: false,
        subItems: [{
          id: "6",
          label: "Nike Supply Chain Partner Portal",
          active: false,
          url: 'https://niketech.service-now.com/scp'
        }],
        hideByRoles: false
      },
    ]
  };

  const [items, setItems] = useState(elements().filter(x => !x.hideByRoles))

  // Click event function example that sets the active value for a nav item
  // Updates the items array with the new items with active state
  const handleClick = item => {

    if (item.path === "/mapping-data") {
      dispatch(selectedButton('Color'));
    }
    const newItems = NavBarConfig?.setActiveValueInNavItems(item.id, items);
    item.id !== "5" && item.id !== "6" && setItems(newItems);
    history.push(item.path);
    item?.url && window.open(item?.url, '_blank');

  };
  useEffect(() => {
    setItems(elements().filter(x => !x.hideByRoles))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (<><NavBarConfig.VerticalNavbar
    navLogoSlot={<NikeApp width={"56px"} height={"56px"} />}
    panelLogoSlot={<></>}
    appName="Materials Data Exchange (MatEx)"
    appSubtitle=""
    onNavigate={handleClick}
    items={items}
  />
    <div className="viewport slide-view-port">
      { }
      {children}
    </div>
  </>);

};
SlideMenuContainer.propTypes = {
  isOpen: PropTypes.bool,
  navMenuclose: PropTypes.func,
  navLinks: PropTypes.array
};

const mapStateToProps = (state, ownProps) => ({
  isMdoAdmin: isMdoAdmin(state),
  isPartnerSA: isPartnerSA(state),
  isPartner: isPartner(state),
  isVendorManager: isVendorManager(state),
  isOpen: selectIsMenuOpen(state),
  navLinks: ownProps.navLinks || []
});

const mapDispatchToProps = dispatch => ({
  requestnavMenuToggle: () => {
    dispatch(navMenuToggle())
  },
  requestNavMenuclose: () => {
    dispatch(navMenuclose())
  },
  requestClearArchive: () => {
    dispatch(clearArchive(null))
  },

})
export default withRouter(
  connect(mapStateToProps,
    mapDispatchToProps
    //{ navMenuToggle, navMenuclose,clearArchive }
  )(SlideMenuContainer)
);
