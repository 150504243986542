import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { displayMessage, putRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    let options = {};
    options.clientId = action.data.clientId?.trim();
    options.groupName = action.data.groupName?.trim();
    options.groupId = action.data.groupId;
    options.consolidatedGroupName = action.data.consolidatedVendorName?.trim() || action.data.consolidatedGroupName?.trim();
    options.engine = action.data.engine;
    options.wave = action.data.wave;
    options.tier = action.data.tier;
    options.location = action.data.locationName?.toString()?.trim();
    options.isDeleted = action.data.isDelete || false;

    const res = yield call(putRequest, apiConfig.editVendorGroupName.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const message = `${response.message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
        // Dispatch action to  update group list
        yield put(createServiceAction("getGroupList", 'requested')());
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* editVendorgroupNameSagas() {
  const serviceType = 'editvendorGroupName';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default editVendorgroupNameSagas;