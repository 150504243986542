import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call} from 'redux-saga/effects';

export function* listener(serviceType) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    
    const res = yield call(getRequest, apiConfig.getGroupList.endPointUrl);
    // Display HTTP status error message
    
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){ 
      
      const response = yield res.json();
     
      // Display server error message
       yield call(getServerValidationMessage, response);     
       
        yield put(createServiceAction(serviceType, 'success')(response));
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* getGroupListSagas() {
  const serviceType = 'getGroupList';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default getGroupListSagas;
