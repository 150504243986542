
import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import {  BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes.js';

const  Root =({store})=> {
 
    return (
      <Provider store={store}>
        <Router>
        <Routes />
        </Router>
      </Provider>
    );
  
}

Root.propTypes = {
  store: PropTypes.object
};

export default Root;