import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    let options = {}; 
    options.clientDetails = action.data.clientDetails;
    options.groupName = action.data.groupName?.trim();
    options.consolidatedGroupName = action.data.consolidatedVendorName?.trim();
    const res = yield call(postRequest, apiConfig.addVendorGroupName.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){  
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const message = `${response.message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
         // Dispatch action to  update group list
        yield put(createServiceAction("getGroupList", 'requested')());
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* addvendorGroupNameSagas() {
  const serviceType = 'addvendorGroupName';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default addvendorGroupNameSagas;