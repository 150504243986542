import React from 'react';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';

import config from '../config.js';

const baseTitle = (window.document && window.document.title) || config.appName;

function formatTitle(pageTitle) {
  return pageTitle ? `${pageTitle} - ${baseTitle}` : baseTitle;
}

const PageTitle = ({ title, children }) => (
  <DocumentTitle title={formatTitle(title)}>{children}</DocumentTitle>
);

PageTitle.propTypes = {
  title: PropTypes.string
};

export default PageTitle;
