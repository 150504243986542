import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';
import { clearModalData } from "../actions/authActions.js";

const defaultState = {
  materialData: [],
};

export default function gridMaterialRowData(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction("materialData", "requested").type:
      return state;
    case createServiceAction("materialData", "success").type:
      const materialGridData = get(action.data, 'data', [])
      const materialData = materialGridData.map(data => {
        let materialObj = {}
        let date = new Date(data.lastSubmittedDate)
        let dd = date.getDate()
        if(dd<10){
          dd = '0' + dd
        }
        let mm = date.getMonth() + 1
        if(mm<10){
          mm = '0' + mm
        }
        materialObj.material_id = data.material_id;
        materialObj.catalogId = data.catalogId;
        materialObj.submittedID = data.catalogItemId;
        materialObj.submittedItemDescription = data.catalogDescription;
        materialObj.nikeMaterialID = data.id;
        materialObj.vendorGroup = data.groupName?data.groupName:"Unknown";
        materialObj.supplierName = data.vendorName;
        materialObj.nikeMaterialDescription = data.description;
        materialObj.nikeMaterialSource = data.source;
        materialObj.status = data.status===false?"Matching material record not found":null
        materialObj.lastsubmitteddate = mm+"-"+dd+"-"+date.getFullYear();
        materialObj.endpointsource = data.endPointSource;
        materialObj.clientId = data.clientId;
        materialObj.pcxSupplierLocationID = data.pcxSupplierLocationID;
        return materialObj;
      })
      return { ...state, materialData: materialData };
      case createServiceAction("materialDataLazyLoad", "requested").type:
      return { ...state, materialData: [...state.materialData]};
      case createServiceAction("materialDataLazyLoad", "success").type:
        const lMaterialGridData = get(action.data, 'data', [])
        const lMaterialData = lMaterialGridData.map(data => {
          let materialObj = {}
          let date = new Date(data.lastSubmittedDate)
          let dd = date.getDate()
          if (dd < 10) {
            dd = '0' + dd
          }
          let mm = date.getMonth() + 1
          if (mm < 10) {
            mm = '0' + mm
          }
          materialObj.material_id = data.material_id;
          materialObj.catalogId = data.catalogId;
          materialObj.submittedID = data.catalogItemId;
          materialObj.submittedItemDescription = data.catalogDescription;
          materialObj.nikeMaterialID = data.id;
          materialObj.vendorGroup = data.groupName?data.groupName:"Unknown";
          materialObj.supplierName = data.vendorName;
          materialObj.nikeMaterialDescription = data.description;
          materialObj.nikeMaterialSource = data.source;
          materialObj.status = data.status===false?"Matching material record not found":null
          materialObj.lastsubmitteddate = mm+"-"+dd+"-"+date.getFullYear();
          materialObj.endpointsource = data.endPointSource;
          materialObj.clientId = data.clientId;
          materialObj.pcxSupplierLocationID = data.pcxSupplierLocationID;
          return materialObj;
        })
        return { ...state, materialData:[...state.materialData, ...lMaterialData] };  
      case clearModalData.type:
        return { ...state, materialData: null};  
    default:
      return state;
  }
}