import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { displayMessage, getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getHasNextRequest, getOffset, getPageNo, stopApiCalls } from '../reducers/authReducer.js';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    const offset = yield select(getOffset);
    const nextPage = yield select(getPageNo);

    const options = {
      ...action.data,
      pageNo: nextPage ? nextPage : 1,
      offset: offset ? offset : 0
    };

    const res = yield call(getRequest, apiConfig.getPOHeader.endPointUrl, options);
    // Display HTTP status error message

    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {

      const response = yield res.json();

      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);

      if (options.summary) {
        yield put(createServiceAction(serviceType, 'successCount')(response));
      }
      else {
        yield put(createServiceAction(serviceType, 'success')(response));
         // Get hasNextRequest status
         const hasNextRequest = yield select(getHasNextRequest); 
         const stopCall = yield select(stopApiCalls);
         if (hasNextRequest === true && !stopCall) {
           yield put(createServiceAction('getPOHeaderLazyLoad', 'requested')(options));
         }
      }
      if (!serverErrorMessage && response.statusCode !== 200) {
        yield put(createServiceAction(serviceType, 'error')())
        const message = response.message ? `${response.message}` : `${response.msg.message}`;
        yield call(displayMessage, 'error', message, 5000);       
      }
    }
    else {
      yield put(createServiceAction(serviceType, 'error')())
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* getPOHeaderSagas() {
  const serviceType = 'getPOHeader';
  const serviceTypeLazyLoad = 'getPOHeaderLazyLoad';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  yield takeEvery(createServiceAction(serviceTypeLazyLoad, 'requested').type, listener, serviceTypeLazyLoad);
}

export default getPOHeaderSagas;
