const globalHeaders = {};

export function setGlobalHeader(name, value) {
  globalHeaders[name] = value;
}

function processError(r, contentType) {
  if (contentType === 'application/json') {
    return r.json().then(res => {
      throw new Error(res ? res.message : r.status);
    });
  }
  throw new Error();
}

function processResponse(r) {
  const contentType = r.headers.get('content-type');
  if (!r.ok) {
    return processError(r, contentType);
  }
  if (contentType === 'application/json') {
    return r.json();
  }
  if (contentType === 'application/octet-stream') {
    return r.blob();
  }
  return r;
}

const  requests =  {
  post(url, payload, headers) {
        return fetch(url, {
          method: 'POST',
          headers: Object.assign({ 'Content-Type': 'application/json' }, globalHeaders, headers),
          body: JSON.stringify(payload)
        }).then(processResponse);
      },
      get(url) {
        return fetch(url, {
          headers: globalHeaders
        }).then(processResponse);
      },
      delete(url) {
        return fetch(url, {
          method: 'DELETE',
          headers: globalHeaders
        }).then(processResponse);
      },
      put(url, payload, headers) {
        return fetch(url, {
          method: 'PUT',
          headers: Object.assign({ 'Content-Type': 'application/json' }, globalHeaders, headers),
          body: JSON.stringify(payload)
        }).then(processResponse);
      }
    }

export default requests;
// export default {
//   post(url, payload, headers) {
//     return fetch(url, {
//       method: 'POST',
//       headers: Object.assign({ 'Content-Type': 'application/json' }, globalHeaders, headers),
//       body: JSON.stringify(payload)
//     }).then(processResponse);
//   },
//   get(url) {
//     return fetch(url, {
//       headers: globalHeaders
//     }).then(processResponse);
//   },
//   delete(url) {
//     return fetch(url, {
//       method: 'DELETE',
//       headers: globalHeaders
//     }).then(processResponse);
//   },
//   put(url, payload, headers) {
//     return fetch(url, {
//       method: 'PUT',
//       headers: Object.assign({ 'Content-Type': 'application/json' }, globalHeaders, headers),
//       body: JSON.stringify(payload)
//     }).then(processResponse);
//   }
// };
