import { clearModalData, poActions } from "../actions/authActions.js";
import { createServiceAction } from "../util/actionHelper.js";

const defaultState = {
  poObj: {"sellerName":"","buyerName":"","poNumber":[]},
};

export default function poGrid(state = defaultState, action) {
  switch (action.type) {
    case poActions.type:
      return { ...state, poObj: {...state.poObj ,...action.payload}};
    case clearModalData.type:
      return  { ...state, poObj:{} };   
    case createServiceAction('getPOHeader', 'success').type:
      return {...state,  poObj: {...state.poObj ,list: []}}
    default:
      return state;
  }
}

export const noOfDays =state => state.poGrid.poObj.noOfDays