import React, { Component } from 'react';


import { Container,H1 } from '../../components/index.js';




export class UserNotExistsError extends Component {
  render(){
   return(
    <Container section className="user"> 
   <H1>
    <span role="img" aria-label=" Not Authorized">
      🙁
    </span>
    You currently do not have system access. Please contact MDO Support to submit access request.
  </H1>
  </Container>);
  }
}
 
export default  UserNotExistsError;
