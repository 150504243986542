import { createServiceAction } from "../util/actionHelper.js";

const defaultState = {
  fileDetailsData: null,
};

export default function fileDetailsData(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getFileDetails', 'requested').type:
      return state;
    case createServiceAction('getFileDetails', 'reset').type:
      return { ...defaultState }
    case createServiceAction('getFileDetails', 'success').type:
      return { ...state, fileDetailsData: action.data };
    case createServiceAction('getUserFileDetails', 'requested').type:
      return state;
    case createServiceAction('getUserFileDetails', 'reset').type:
      return { ...defaultState }
    case createServiceAction('getUserFileDetails', 'success').type:
      return { ...state, fileDetailsData: action.data };
    default:
      return state;
  }
}