import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    let res = []
    if (serviceType === "updateMaterialMappingAll") {
      res = yield call(putRequest, apiConfig.updateMaterialMapping.endPointUrlAll, action.data);
    }
    else {
      res = yield call(putRequest, apiConfig.updateMaterialMapping.endPointUrl, action.data);
    }

    // Display HTTP status error message

    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json();

      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);

      if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const message = `${response.message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
        if (serviceType === "updateMaterialMappingAll") {
          yield put(createServiceAction('materialMappingDetailsAll', 'requested')());
        }
        else {
          yield put(createServiceAction('materialMappingDetails', 'requested')());
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* updateMaterialMappingSagas() {
  const serviceType = 'updateMaterialMapping';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  const serviceTypeAll = 'updateMaterialMappingAll';
  yield takeEvery(createServiceAction(serviceTypeAll, 'requested').type, listener, serviceTypeAll);
}

export default updateMaterialMappingSagas;