import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage} from '../services/serverValidations.js';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { getButtonStatus, getHasNextRequest, getOffset, getPageNo, selectUserId, stopApiCalls } from "../reducers/authReducer.js";


export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    const offset = yield select(getOffset);
    const nextPage = yield select(getPageNo);
    const userId =yield select(selectUserId);

    const options = {
      userId: userId || action.data.userId,
      pageNo: nextPage? nextPage : 1,
      offset: offset? offset : 0
    };
    
      const res = yield call(getRequest, apiConfig.vendorGridData.endPointUrl, options);
      // Display HTTP status error message
      const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
      if(!statusErrorMessage){  
        // Parse response object
        const response = yield res.json();
        // Display server error message
        const serverErrorMessage = yield call(getServerValidationMessage, response);
        if (!serverErrorMessage && response.statusCode === 200) {
          yield put(createServiceAction(serviceType, 'success')(response));          
          // Get hasNextRequest status
            const hasNextRequest = yield select(getHasNextRequest);        
            const buttonStatus = yield select(getButtonStatus);
            const stopCall = yield select(stopApiCalls);
            if (hasNextRequest === true && buttonStatus ==='Vendor' && !stopCall) {
              yield put(createServiceAction('vendorGridDataLazyLoad', 'requested')());
       }
      }
    }
    else {
      yield put(createServiceAction(serviceType, 'success')({}));
    }
  } catch (e) {
     
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* vendorGridDataSagas() {
    const serviceType = 'vendorGridData';
    const serviceTypeLazyLoad = 'vendorGridDataLazyLoad';
    yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
    yield takeEvery(createServiceAction(serviceTypeLazyLoad, 'requested').type, listener, serviceTypeLazyLoad);
  }
export default vendorGridDataSagas;
