import { useSelector, useDispatch  } from 'react-redux';
import { useEffect,useState } from 'react';
import PropTypes from 'prop-types';

import { Container, H3, TinyPopover } from '../../components/index.js';
import { NavLink } from 'react-router-dom';
import { navMenuToggle } from '../../actions/navigationActions.js';
import { selectIsMenuOpen } from '../../reducers/navigationReducer.js';
import * as Constants from '../../constants/header.js';
import { selectFullName, selectUserAccessToken, selectUserDetails, selectGroup, selectUserId, isMdoAdmin } from '../../reducers/authReducer.js';
import { createServiceAction } from '../../util/actionHelper.js';
import NotificationArea from '../../views/notifications/notificationArea/notificationArea.js'
import moment from 'moment';


const  Header =({isErrorRoute}) => {
  const { isOpen, name , mdoUser, userToken, userExist,  notifications } = useSelector(state => ({
    isOpen: selectIsMenuOpen(state),
    name: selectFullName(state),
    mdoUser: isMdoAdmin(state),
    userToken: selectUserAccessToken(state),
    userExist: !!selectUserDetails(state).userId,
    userGroup: selectGroup(state),
    userId: selectUserId(state),
    notifications: state.listNotification.notificationDetails,
    allUnreaded: state.listNotification.allUnreaded
  }));
  const dispatch = useDispatch();
  const [status, setStatus] = useState(true);
    
  useEffect(() => {
    function fetchListNotification() {
      const todayDate = moment.utc(new Date()).format('YYYY-MM-DD');
      dispatch(createServiceAction('listNotification', 'requested')(todayDate));
      } 

    fetchListNotification()
  },[dispatch])
  const notificationStatus = (status) => {
    setStatus(status);
  }

    return (
      <span>
        <iframe className="fake-iframe fake-iframe-header-height" title='fake' id='fake' src='about:blank' ></iframe>
        <Container section className={`header-container ncss-row ${isOpen ? 'hide-header' : ''}`}>
          <div className="ncss-col-sm-8">
            {userToken && userExist && !isErrorRoute ? (
              <button type="button" className="menu-btn inline-display ncss-btn fs16-sm" onClick={() => dispatch(navMenuToggle())}>
                <i className="g72-menu" />
              </button>
            ) : null}
            <NavLink to={userToken && userExist && !isErrorRoute ? '/' : '/error'} activeClassName="active">
              <H3 className="inline-display relative-position header-brand">
                <i className="g72-swoosh nav-brand fs22-sm menu-logo" />
                <span className="ncss-brand text-color-accent">{Constants.APPNAME}</span>
              </H3>
            </NavLink>
          </div>

          <div className="ncss-col-sm-4 u-bold">

            <span className="inline-display relative-position text-color-grey top-align ">
              <span>
                {userToken && userExist && !isErrorRoute ? (
                  <span className="button-notification">
                    <TinyPopover
                      activeStatus={false}
                      notificationStatus={notificationStatus}
                      children={(
                        <ul>
                          <NotificationArea isMdoUser={mdoUser ? true : false} status={status} notifications={notifications}  />
                        </ul>
                      )}
                    />
                  </span>
                ) : null}
              </span>


              {name ? (
                <span className ='user-text'>
                   <span ><i className="fas fa-user-circle"></i>  {Constants.LOGGEDTEXT} </span>{name}{' '}
                </span>
              ) : null}
            </span>
          </div>


        </Container>

      </span>
    );
  
}

Header.propTypes = {
  isOpen: PropTypes.bool,
  navMenuToggle: PropTypes.func,
  name: PropTypes.string,
  userToken: PropTypes.string,
  userExist: PropTypes.bool
};

export default Header;
