import React from 'react';

import Tooltip from './Tooltip';

const InfoTooltip = ({ children, className, useBareClass, ...props }) => (
  <div className={`${useBareClass || 'fs18-sm d-sm-ib'} ${className || ''}`}>
    <Tooltip {...props} content={children} placement="right">
      <i className="fa fa-info-circle text-color-accent fs18-sm" />
    </Tooltip>
  </div>
);

export default InfoTooltip;
