//import authSagas from './authSagas.js';


import { fork, all } from 'redux-saga/effects';
import login from './loginSagas';
import requestUserProfile from './getProfileSagas';
import getMaterialRowData from './getMaterialRowDataSagas';
import colorSearchSagas from './getColorSearchSagas';
import vendorSearchSagas from './getVendorSearchSagas';
import colorGridDataSagas from './getColorGridDataSagas';
import vendorGridDataSagas from './getVendorGridDataSagas';
import addUserSagas from './addUserSagas';
import dashboardCountSagas from './dashboardCountSagas';
import vendorFetchSagas from './getVendorFetchSagas';
import getUserDetailsSagas from './getUserDetailsSaga';
import createProfileSagas from './createProfileSagas';
import getGroupListSagas from './getGroupListSagas'; 
import updateVendorSagas from './updateVendorSagas';
import updateMaterialSagas from './updateMaterialSagas';
import locationSearchSagas from './locationSearchSagas';
import updateUserSagas from './updateUserSagas';
import updateColorSagas from './updateColorSagas';
import materialVendorSearchSagas from './getMaterialVendorSearchSagas';
import materialSearchByLocSagas from './getMaterialSearchByLocSagas';
import getVendorDetailsSagas from './getVendorDetailsSagas';
import updateVendorDetailsSagas from './updateVendorDetailsSagas';
import updateColumnSagas from './updateColumnSagas';
import getEditVendorMappingSagas from './getEditVendorMappingSagas';
import updateVendorMappingSagas from './updateVendorMappingSagas';
import getEditMaterialMappingSagas from './getEditMaterialMappingSagas';
import updateMaterialMappingSagas from './updateMaterialMappingSagas';
import getColorMappingSagas from './getColorMappingSagas';
import updateColorMappingSagas from './updateColorMappingSagas';
import submitUploadSagas from './submitUploadSagas';
import addvendorGroupNameSagas from './addVendorgroupNameSagas';
import editVendorgroupNameSagas from './editVendorgroupNameSagas';
import getUploadedFilesSagas from './getUploadedFilesSagas';
import getFileDetailsSagas from './getFileDetailsSaga';
import getPOHeaderSagas from './getPOHeaderSagas';
import getPOItemSagas from './getPOItemSagas';
import getPOMappingSagas from './getPOMappingSagas';
import sendPOEmailSagas from './sendPOEmailSagas';
import addPoShipmentMappingSagas from './addPoShipmentMappingSagas';
import poVendorSearchSagas from './getPOVendorSearchSagas';
import getPOUserDetailsSagas from './getPoUserDetailsSagas';
import getIdLockerUsersAccessSagas from './getIdLockerUsersAccessSagas';
import getIdLockerUsersSagas from './getIdLockerUsersSagas';
import getAllAccessForIdlockerUserSagas from './getAllAccessForIdlockerUserSagas';
import submitIdLockerUsersSagas from './submitIdLockerUsersSagas';
import getIdLockerRequestListSagas from './getIdLockerRequestListSagas';

function* rootSaga() {
  yield all([
    fork(login),
    fork(requestUserProfile),
    fork(getMaterialRowData),
    fork(colorSearchSagas),
    fork(vendorSearchSagas),
    fork(colorGridDataSagas),
    fork(vendorGridDataSagas),
    fork(addUserSagas),
    fork(dashboardCountSagas),
    fork(vendorFetchSagas),
    fork(getUserDetailsSagas),
    fork(getEditVendorMappingSagas),
    fork(createProfileSagas),
    fork(getGroupListSagas),
    fork(updateVendorSagas),
    fork(updateMaterialSagas),
    fork(locationSearchSagas),
    fork(updateUserSagas),
    fork(updateVendorMappingSagas),
    fork(updateColorSagas),
    fork(materialVendorSearchSagas),
    fork(materialSearchByLocSagas),
    fork(getVendorDetailsSagas),
    fork(updateVendorDetailsSagas),
    fork(updateColumnSagas),
    fork(getEditMaterialMappingSagas),
    fork(updateMaterialMappingSagas),
    fork(getColorMappingSagas),
    fork(updateColorMappingSagas),
    fork(submitUploadSagas),
    fork(addvendorGroupNameSagas),
    fork(editVendorgroupNameSagas),
    fork(getUploadedFilesSagas),
    fork(getFileDetailsSagas),
    fork(getPOHeaderSagas),
    fork(getPOItemSagas),
    fork(getPOMappingSagas),
    fork(sendPOEmailSagas),
    fork(addPoShipmentMappingSagas),
    fork(poVendorSearchSagas),
    fork(getPOUserDetailsSagas),
    fork(getIdLockerUsersAccessSagas),
    fork(getIdLockerUsersSagas),
    fork(submitIdLockerUsersSagas),
    fork(getAllAccessForIdlockerUserSagas),
    fork(getIdLockerRequestListSagas)
  ]);
}

export default rootSaga;