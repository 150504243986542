import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';
import { clearModalData } from "../actions/authActions.js";

const defaultState = {
  colorSearch: null,
};

export default function gridColorSearch(state = defaultState, action) {
  switch (action.type) {    
    case createServiceAction('colorSearch', 'requested').type:
      return state;
    case createServiceAction('colorSearch', 'success').type:
      return { ...state, colorSearch: get(action.data, 'data', undefined) };
      case clearModalData.type:
        return { ...state, colorSearch: null};
    default:
      return state;
  }
}