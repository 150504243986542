export const oAuthConfig = {
  issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
  clientId:'nike.gsm.mdo-ui',
  redirectUriProd: '',
  responseType: 'id_token token',
  scopes: ['manufacture:mdo.curate.data::execute:','openid', 'profile', 'email', 'address', 'phone', 'offline_access'],
  redirectUri: window.location.origin,
  authorizationUrlProd: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/authorize',
  OktaTokenUrlProd:'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7/v1/token',
  allowedIdleTime: 1800000 // (milliseconds) Value should be below 1 hour(Current OKTA token expiration time). This is the time by which application will wait for before labelling user as inactive.

};

export const oidc = {
  issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
  clientId:'nike.gsm.mdo-ui',
  scopes: ['manufacture:mdo.curate.data::execute:','openid', 'profile', 'email', 'address', 'phone', 'offline_access'],
  redirectUri: window.location.origin+ '/implicit/callback',
  pkce: true,
  tokenManager :{
    autoRenew:true,
    auto_renew:true
  }
};
