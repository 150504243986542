import { get } from "lodash";
import { createServiceAction } from "../util/actionHelper.js";

const defaultState = {
  poGridData: null,
};

export default function poMappingGrid(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getPOMapping', 'requested').type:
      return state;
    case createServiceAction('getPOMapping', 'success').type:
      const gridData = get(action.data, 'data', [])
      const poGridData = gridData.map(data => {
        const poObj = {
          vendorName: data.vendor_name,
          vendorAddress: data.address1,
          clientId: data.client_id,
          emailId: data.email,
          user: data.user_name,
          id: data?.id
        }
        return poObj;
      })
      return { ...state, poGridData };
    case createServiceAction('getPOMapping', 'error').type:
      return { ...state, poGridData: [] };
    default:
      return state;
  }
}