import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import {  Button, IconButton } from '../index.js';

import CommentsCard from './commentsCard/CommentsCard.js';

const CommentModal = ({isOpenComment, submitValue, closeModal, commentsAreaShow, commentAreaChange, showCancel, commentsDetails}) => {
 
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
   if(isOpenComment){
    setIsShow(false)
   }
  },[isOpenComment])


  
  function commentChange(value){
    if(!value || !value.replace(/\s/g, '').length){
      setIsShow(false)
    } else {
      setIsShow(true)
    }
    commentAreaChange(value)

  }
    return (
      <Modal isOpen={isOpenComment} className="comment-modal-container" overlayClassName="modal-overlay">
           <div className="ncss-col-sm-11 mb2-sm comments-heading" >
           <i class="fas fa-comments"></i> {`NOTES(${commentsDetails?commentsDetails.length:''})`}
           </div>
          <div className="ncss-col-sm-1" >
        <IconButton icon="times" className="modal-close-btn" onClick={() => closeModal()} />
        </div>
        <div className={`display-comments-area ${showCancel?"add-display-comments-height":"add-show-comments-height"}`}>
        <CommentsCard commentsDetails={commentsDetails} />
        </div>
        {commentsAreaShow ? (
        <div className={`comment-section ${showCancel? " display-comment-height":" add-comment-height"}`}>
            <div className="ncss-col-sm-12">
              <textarea className ="comment-textarea" onChange={(e)=>commentChange(e.target.value)} placeholder="Add your comments"/>  
            </div>
          <div className="ncss-col-sm-12 comments-buttons align-center">
          <div className="ncss-col-sm-3  ">
            {isShow?
              <Button
                className="ncss-btn-secondary-dark u-bold secondary-button new-material"
                onClick={() => {
                  submitValue();
                }}
              >
                UPDATE
              </Button>
              :
              <Button className="ncss-btn-secondary-dark  u-bold secondary-button update-comment-button" disabled>
                UPDATE
              </Button>
            }
            </div>
            <div className=" ncss-col-sm-3  ">
              <Button
                className="ncss-btn-grey u-bold no-button button-width-adjust"
                onClick={() => closeModal()}
              >
                CANCEL
              </Button>
            </div>
            
          </div>
          </div>
        ) : null}
        {showCancel?(
            <div className="comment-section">
          <div className="ncss-col-sm-12 comments-buttons align-center">
            <div className="bottom-section ncss-col-sm-3 ">
              <Button
                className="ncss-btn-grey u-bold no-button button-width-adjust"
                onClick={() => closeModal()}
              >
                CANCEL
              </Button>
            </div>
           
          </div>
          </div>
        ) : null}
      </Modal>
    );
  
}
CommentModal.propTypes = {
  isOpenComment: PropTypes.bool,
  submitValue: PropTypes.func,
  closeModal: PropTypes.func,
  message: PropTypes.string,
  commentsAreaShow: PropTypes.bool
};
CommentModal.defaultProps = {
  commentsAreaShow: true,
  showCancel: false
};
export default CommentModal;
