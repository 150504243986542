import React from 'react';
import PropTypes from 'prop-types';

import { Container, H1, NotFound } from '../index.js';

const is404 = error =>
  error && ((error.status && error.status === 404) || (error.response && is404(error.response)));

const isusernotfound = "Cannot read property 'map' of undefined";
const Generic = ({ error }) => {
  if (is404(error)) return <NotFound />;
  if (isusernotfound)
    return (
      <Container section className="usernotfound">
        <H1>
          <span role="img" aria-label=" Not Authorized">
            🙁
          </span>
          You currently do not have system access. Please contact MDO support to submit access request.
        </H1>
      </Container>
    );
  return (
    <Container section>
      <H1>An error occured, unable to load page.</H1>
      {error ? <pre>{error.message || error}</pre> : null}
    </Container>
  );
};

Generic.propTypes = {
  error: PropTypes.func
};
export default Generic;
