import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest } from '../util/httpRequest.js';
import { displayMessage } from '../util/messageHelper.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';
import { isEmpty, toLower } from 'lodash';


export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    let options = {};
    options.userId = action.data.userId;
    options.endpoint = toLower(action.data.button);    
    options.fields = action.data.gridColumns?action.data.gridColumns:action.data.columns; 
    options.filters = !isEmpty(action.data.filterOption)?action.data.filterOption:[{}]


    const res = yield call(putRequest, apiConfig.columnUpdate.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const message = "Search history has been successfully updated.";
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* updateColumnSagas() {
  const serviceType = 'updateColumn';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default updateColumnSagas;