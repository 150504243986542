// chip
import React from 'react';
import PropTypes from 'prop-types';
import '../../stylus/chip.css';

export const Chip = ({ label, deleteValue }) => {
  return (
    (<div className="chip">
      {label}
      {deleteValue && <span className="closebtn" onClick={() => deleteValue(label)}>
        &times;
      </span>}
    </div>)
  );
};

Chip.propTypes = {
  label: PropTypes.string
};

export default Chip;