import React from 'react';
import PropTypes from 'prop-types';

import { Container, PageHeader, PageTitle, Spinner } from './index.js';

const PageSpinner = ({ title }) => (
  <div>
    <PageTitle title={title} />
    <PageHeader title={title} />

    <Container fluid>
      <Spinner large />
    </Container>
  </div>
);

PageSpinner.propTypes = {
  title: PropTypes.string
};
export default PageSpinner;
