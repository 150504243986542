import { clearModalData } from "../actions/authActions.js";
import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';
import { dateFormatUTC } from "../util/dateHelper.js";

const dateFormat = (date) => {
  if (date !== null && date !== undefined) {
    date = new Date(date)
    let dd = date.getDate()
    if (dd < 10) {
      dd = '0' + dd
    }
    let mm = date.getMonth() + 1
    if (mm < 10) {
      mm = '0' + mm
    }
    return mm + "-" + dd + "-" + date.getFullYear()
  }
  return null
}

const defaultState = {
  poGridData: null,
  poCount: null
};

export default function poHeaderGrid(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getPOHeader', 'requested').type:
      return {...state, poGridData: null,};
    case createServiceAction('getPOHeader', 'success').type:
      const gridData = get(action.data, 'data', [])
      const poData = gridData.map(data => {
        let poObj = {}
        poObj.vendorGroup = data.vendor_group;
        poObj.poNumber = data.po_number;
        poObj.buyerName = data.buyer_name;
        poObj.sellerName = data.seller_name;
        poObj.submissionDate = dateFormatUTC(data?.submission_date)
        poObj.poState = !isNaN(data?.percentage) && data?.percentage !== null && data?.percentage !== undefined ? data?.percentage : 0;
        poObj.sendMail = dateFormat(data.email_send_last_update);
        poObj.po_state = data.po_state;
        poObj.updatedDate = dateFormat(data.updatedAt);
        poObj.buyerId = data?.buyer_id;
        poObj.sellerId = data?.seller_id;
        poObj.po_lifecycle_id = data?.po_lifecycle_id
        return poObj;
      })
      return { ...state, poGridData: poData };
    case createServiceAction('getPOHeader', 'successCount').type:
      return { ...state, poGridData: null, poCount: get(action.data, 'data', []) };
      case createServiceAction("getPOHeaderLazyLoad", "requested").type:
      return { ...state, poGridData:[...state.poGridData]};
    case createServiceAction('getPOHeaderLazyLoad', 'success').type:
      const gridDatal = get(action.data, 'data', [])
      const poDatal = gridDatal.map(data => {
        let poObj = {}
        poObj.vendorGroup = data.vendor_group;
        poObj.poNumber = data.po_number;
        poObj.buyerName = data.buyer_name;
        poObj.sellerName = data.seller_name;
        poObj.submissionDate = dateFormatUTC(data?.submission_date)
        poObj.poState = !isNaN(data?.percentage) && data?.percentage !== null && data?.percentage !== undefined ? data?.percentage : 0;
        poObj.sendMail = dateFormat(data.email_send_last_update);
        poObj.po_state = data.po_state;
        poObj.updatedDate = dateFormat(data.updatedAt);
        poObj.buyerId = data?.buyer_id;
        poObj.sellerId = data?.seller_id;
        poObj.po_lifecycle_id = data?.po_lifecycle_id
        return poObj;
      })
      return { ...state, poGridData: [...state.poGridData,...poDatal] };
    case createServiceAction('getPOHeader', 'error').type:
      return { ...state, poGridData: [] };
    case clearModalData.type:
      return { ...state, poGridData: null };
    default:
      return state;
  }
}
