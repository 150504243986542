export const mergeArrays = (arrays) => {
    let jointArray = []
    if (arrays) {
        arrays.forEach(array => {
            if (Array.isArray(array)) {
                jointArray = [...jointArray, ...array]
            }
        });
    }

    return [...new Set(jointArray)]
}