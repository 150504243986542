import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Label from './Label';

export class Radiobutton extends Component {
  constructor(...props) {
    super(...props);
    this.id += 1;
  }

  render() {
    const { checked, label, title, name, id, value, icon, isIcon, onChange, mandatory, disabled } = this.props;
    return (
      <div>
        <input
          type="radio"
          className="ncss-radio"
          id={`ncss-radio-${id}`}
          name={name}
          checked={checked}
          value={value}
          onChange={() => onChange(value)}
          disabled={disabled}
        />
        <Label className="pl6-sm" htmlFor={`ncss-radio-${id}`} title={title}>
          {isIcon ? <i className={icon} /> : <span>{label}</span>}
        </Label>
        {mandatory ? <span className="astericks">&#42;&#42;</span> : null}
      </div>
    );
  }
}
export const renderRadiobutton = ({ input, label, id, isIcon, type, mandatory, disabled, ...rest }) => (
  <Radiobutton
    {...input}
    {...rest}
    type={type}
    label={label}
    name={input.name}
    isIcon={isIcon}
    id={id}
    value={input.value}
    mandatory={mandatory}
    disabled = {disabled}
    checked={input.checked}
    onChange={input.onChange}
  />
);

Radiobutton.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  isIcon: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.number,
  mandatory: PropTypes.bool
};
Radiobutton.defaultProps = {
  mandatory: false
};
export default renderRadiobutton;
