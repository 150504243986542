import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Label from './Label';

const types = {
  input: 'ncss-input-container',
  textarea: 'ncss-textarea-container',
  radio: 'ncss-radio-container',
  select: 'ncss-select-container',
  checkbox: 'ncss-checkbox-container'
};

const labelBeforeTypes = ['input', 'select', 'textarea', 'text'];
const labelAfterTypes = ['radio', 'checkbox'];

export default class InputContainer extends Component {
  render() {
    const { type } = this.props || 'input';
    const { htmlFor } = this.props || null;
    const inputType = types[type];
    const {
      label,
      title,
      error,
      labelBefore,
      labelAfter,
      children,
      id,
      className,
      childClassName
    } = this.props;

    const childrenArray = React.Children.toArray(children);

    if (label) {
      const inputLabel = (
        <Label key="label" title={title} htmlFor={htmlFor} className={childClassName}>
          {label}
        </Label>
      );

      if (labelBefore || (!labelAfter && labelBeforeTypes.indexOf(type))) {
        childrenArray.unshift(inputLabel);
      } else if (labelAfter || labelAfterTypes.indexOf(type)) {
        childrenArray.push(inputLabel);
      }
    }
    if (type === 'textarea') {
      const inputTextarea = <textarea className="ncss-textarea p4-sm" key={label} />;
      childrenArray.push(inputTextarea);
    }
    if (type === 'radio') {
      const inputRadio = <input type="radio" name="groupName" className="ncss-radio" id={id} key={id} />;
      childrenArray.push(inputRadio);
    }

    if (error) {
      childrenArray.push(
        <div className="ncss-error-msg" key="error">
          {error}
        </div>
      );
    }
    return (
      // <div className={`ncss-form-group ${extraClassName || ''}`}>
      <div className={`ncss- ${inputType} ${className} ${error ? 'error' : ''}`}>{childrenArray}</div>
      // </div>
    );
  }
}

InputContainer.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.func,
  labelBefore: PropTypes.bool,
  labelAfter: PropTypes.bool,
  className: PropTypes.string,
  childClassName: PropTypes.string
};

InputContainer.defaultPropTypes = {
  childClassName: ''
};
