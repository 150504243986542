import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { displayMessage, getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, endPointUrl, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    const options = { ...action.data };
    if (options?.retry) delete options.retry;
    const res = yield call(getRequest, endPointUrl, options);
    // Display HTTP status error message

    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {

      const response = yield res.json();

      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);

      yield put(createServiceAction(serviceType, 'success')({ response, isErrorFile: options?.response_file, isSuccessFile: options?.original_file_name, retry: action.data?.retry }));
      if (!serverErrorMessage && response.statusCode !== 200) {
        const message = response.message ? `${response.message}` : `${response.msg.message}`;
        yield call(displayMessage, 'error', message, 5000);
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* getFileDetailsSagas() {
  yield takeEvery(createServiceAction('getFileDetails', 'requested').type, listener, 'getFileDetails', apiConfig.getFileDetails.endPointUrl);
  yield takeEvery(createServiceAction('getUserFileDetails', 'requested').type, listener, 'getUserFileDetails', apiConfig.getUserFileDetails.endPointUrl);
}

export default getFileDetailsSagas;