import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const NotificationsMessage =()=> {
  
    return (
      <div className="main">
        <ToastContainer position="top-center" pauseOnVisibilityChange draggable pauseOnHover />
      </div>
    );
  
}

export default NotificationsMessage;
