import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

export default class Markdown extends Component {
  static defaultProps = {
    allowHtml: false
  };

  render() {
    const { allowHtml } = this.props;
    const { source } = this.props;
    // const { renderers } = this.props.renderers || {};
    const { renderers } = this.props || {};
    return (
      <div className="markdown-container">
        <ReactMarkdown
          source={source || ''}
          escapeHtml={!allowHtml}
          renderers={{ ...ReactMarkdown.renderers, ...renderers }}
        />
      </div>
    );
  }
}

Markdown.propTypes = {
  allowHtml: PropTypes.bool,
  source: PropTypes.string
};
