import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/httpRequest.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage} from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';
import { displayMessage } from '../util/messageHelper.js';


export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    let options = {};
    options.name = action.data;
    
    const res = yield call(getRequest, apiConfig.locationSearch.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){  
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')(response));
       
      }
      if (!serverErrorMessage && response.statusCode === 500) {
        yield put(createServiceAction(serviceType, 'success')({}));
        const message = `${response.message}`;
        // Display error message
        yield call(displayMessage, 'error', message, 5000);
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* locationSearchSagas() {
  const serviceType = 'locationSearch';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default locationSearchSagas;       




