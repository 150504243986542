import React from 'react';

const getButtonClass = props => {
  const style = [`ncss-btn-${props.color || 'black'} ncss-brand`];

  if (!props.matchCase) style.push('u-uppercase');
  if (props.small) style.push('pt1-sm pb1-sm prl2-sm');
  else if (props.thin) style.push('');
  // Not yet determined
  else if (props.large) style.push('');
  // Not yet determined
  else style.push('pt3-sm pr5-sm pb3-sm pl5-sm pt2-lg pb2-lg');

  if (props.extraClassName) style.push(props.extraClassName);

  return style.join(' ');
};

// This is a tactic for keeping extra props from getting to the wrapped element
// See https://facebook.github.io/react/warnings/unknown-prop.html for details
const Button = ({ extraClassName, small, thin, large, submit, className, children, ...props }) => (
  <button
    {...{
      type: submit ? 'submit' : 'button',
      ...props,
      className: className || getButtonClass({ ...props, small, thin, large, extraClassName })
    }}
  >
    {children}
  </button>
);

export default Button;

export const IconButton = ({ icon, color, title, extraClassName, ...props }) => (
  <button type="button" className={`btn icon-solo ${extraClassName || ''}`} {...props}>
    <i className={`fas fa-${icon} fa-fw text-color-${color || 'black'}`} />
    <span>{title}</span>
  </button>
);
