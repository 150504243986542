import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call, select} from 'redux-saga/effects';
import { noOfDays } from '../reducers/poReducer.js';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    
    const res = yield call(postRequest, apiConfig.sendPOEmail.endPointUrl, action.data);
    // Display HTTP status error message
    
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){ 
      
      const response = yield res.json();
     
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);     
       
       if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')());
        const message = response.message?response.message:"Mail sent successfully.";
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
        // Dispatch action to call po header
        yield put(createServiceAction("getPOHeader", 'requested')({noOfDays:yield select(noOfDays), poStatus: "Open" }));
      }
      if (!serverErrorMessage && response.statusCode !== 200){
        yield put(createServiceAction(serviceType, 'error')());
        const message = response.message?`${response.message}`:`${response.errors[0].code}`;
        // Display error message
        yield call(displayMessage, 'error', message, 5000);
      }
    } 
    else {
      yield put(createServiceAction(serviceType, 'error')());
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* sendPOEmailSagas() {
  const serviceType = 'sendPOEmail';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default sendPOEmailSagas;
