import { put, takeEvery, call } from "redux-saga/effects";
import { createServiceAction } from "../util/actionHelper.js";
import { apiConfig } from '../configs/apiConfig.js';
import { getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

export function* listener(serviceType, action) {
    try {
      yield put(createServiceAction(serviceType, 'loading')());
      if (action.data) {
        const options = {
            userId: action.data,
        };
  
        const res = yield call(getRequest, apiConfig.dashboardCount.endPointUrl, options);
        // Display HTTP status error message
        const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
        if(!statusErrorMessage){ 
          const response = yield res.json();
          // Display server error message
          const serverErrorMessage = yield call(getServerValidationMessage, response);
          if (!serverErrorMessage && response.statusCode === 200) {
            yield put(createServiceAction(serviceType, 'success')(response));
          }
        }
      }
      else {
        yield put(createServiceAction(serviceType, 'success')({}));
      }
    } catch (e) {
      yield put(createServiceAction(serviceType, 'error')(e));
    }
  }

  function* dashboardCountSagas() {
    const serviceType = 'dashboardCountData';
    yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  }
  
  export default dashboardCountSagas;