import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Player, BigPlayButton } from 'video-react';

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBegining: true
        };
    }

    onPlay = (e) => {
        const { onPlayClick } = this.props;
        const { isBegining } = this.state;
        onPlayClick(e.target.src, isBegining);
    }
    onLoadedData = () => {
        this.setState({ isBegining: true })
    }
    onPause = () => {
        this.setState({ isBegining: false })
    }
    render() {
        const { url, poster } = this.props;

        return <Player
            playsInline
            poster={poster}
            src={url}
            className="video-player video-player-border"
            onPlay={e => this.onPlay(e)}
            onLoadedData={e => this.onLoadedData(e)}
            onPause={e => this.onPause(e)}
        >
            <BigPlayButton className="test" position="center" />
        </Player>;
    }
}
VideoPlayer.propTypes = {
    children: PropTypes.object
};

export default VideoPlayer;
