import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';

const defaultState = {
  materialVendorData: null,
};

export default function materialVendorSearch(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('materialVendorSearch', 'requested').type:
      return { ...state, materialVendorData: null };
    case createServiceAction('materialVendorSearch', 'success').type:
      const gridData = get(action.data, 'data', [])
      const vMatData = gridData.map(data => {
        let vMatObj = {}
        vMatObj.supplierID = data.supplierIdentifier
        vMatObj.supplierName = data.supplierName;
        return vMatObj;
      })
      return { ...state, materialVendorData: vMatData };
    default:
      return state;
  }
}