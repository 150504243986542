import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';

const defaultState = {
  vendorData: []
};

export default function vendorDetails(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('vendorDetails', 'requested').type:
      return state;
    case createServiceAction('vendorDetails', 'success').type:
      const gridData = get(action.data, 'data', [])
      const vData = gridData.map(data => {
        let date =new Date(data.addedDate)
          let dd = date.getDate()
          if (dd < 10) {
            dd = '0' + dd
          }
          let mm = date.getMonth() + 1
          if (mm < 10) {
            mm = '0' + mm
          }        
        let vendorObj = {}
        vendorObj.vendorId = data.vendorId;
        vendorObj.vendorName = data.name;
        vendorObj.address1 = data.address1;
        vendorObj.address2 = data.address2;
        vendorObj.address3 = data.address3;
        vendorObj.area = data.area;
        vendorObj.city = data.city;
        vendorObj.state = data.state;
        vendorObj.postalCode = data.postal_code;
        vendorObj.county = data.county;
        vendorObj.addedDate = mm+"-"+dd+"-"+date.getFullYear();
        vendorObj.country = data.country;
        vendorObj.vendorNameType = data.type==="vendorLegalName"?"Vendor Legal Name":"Mfg Location Name";
        vendorObj.activeStatus = !data.isDeleted
        return vendorObj;
      })
      return { ...state, vendorData: vData };
    default:
      return state;
  }
}