import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';

const defaultState = {
  vendorMappingGridData: [],
};

export default function editVendorMapping(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('vendorMappingDetails', 'requested').type:
      return state;
    case createServiceAction('vendorMappingDetails', 'success').type:
      const gridData = get(action.data, 'data', [])
      const uData = gridData.map(data => {
        let date = new Date(data?.updatedAt)
        let dd = date.getDate()
        if (dd < 10) {
          dd = '0' + dd
        }
        let mm = date.getMonth() + 1
        if (mm < 10) {
          mm = '0' + mm
        }
        let obj = {
          submittedVendorName: data?.name,
          nikeVendorName: data?.meta_name,
          submittedVendorAddress1: data?.address,
          nikeAddress1: data?.meta_address,
          nikeCountry: data?.country,
          status: data?.status,
          mappingDate: data?.updatedAt ? mm + "-" + dd + "-" + date.getFullYear() : null,

          vendorId: data?.vendor_id,
          vendorNameType:data.type==="vendorLegalName"?"Vendor Legal Name":"Mfg Location Name"

        };
        return obj;
      })
      return { ...state, vendorMappingGridData: uData };
    default:
      return state;
  }
}