import React from 'react';

import { Container, H1 } from '../index.js';

const NotFound = () => (
  <Container section>
    <H1>
      <span role="img" aria-label=" Not Authorized">
        🙁
      </span>
      The resource you requested was not found
    </H1>
  </Container>
);

export default NotFound;
