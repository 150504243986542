import React, { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import {  Popover,  ArrowContainer } from 'react-tiny-popover';

const  TinyPopOver =({ children, activeStatus })=> {
    
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    

      useEffect(() => {
        setIsPopoverOpen(activeStatus)
      }, [activeStatus]);
    
    
    
    /* const displayPopOver = () => {
        setIsPopoverOpen(!isPopoverOpen)
    }; */

        return (
            <Popover
                isOpen={isPopoverOpen}
                positions={["bottom"]}
                containerClassName='notification-tiny-popover'
                containerStyle={{}}
                padding={8}
                content={({ position, popoverRect, childRect }) => (
                    <ArrowContainer
                        position={position}
                        popoverRect={popoverRect}
                        childRect={childRect}
                        arrowColor="rgb(0, 120, 185)"
                        arrowSize={6}
                        arrowStyle={{ opacity: 1, left: '82%' }}
                    >
                        <div className="notification-popover-section">
                            {children}
                        </div>
                    </ArrowContainer>
                )}
            >
                <span>
                    {/* <button type="button" className=" inline-display ncss-btn fs16-sm pt1-sm notification-button" onClick={displayPopOver}>
                        {!activeStatus ? <i className="fas fa-bell bell-style-inactive"></i> : <i className="fas fa-bell bell-style"></i>}
                    </button> */}
                </span>
            </Popover>
        );
    }

TinyPopOver.propTypes = {
    children: PropTypes.object
};

export default TinyPopOver;
