import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';
import { clearModalData } from "../actions/authActions.js";

const defaultState = {
  locationSearch: null,
};

export default function gridLocationSearch(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('locationSearch', 'requested').type:
      return state;
    case createServiceAction('locationSearch', 'success').type:
      const gridData = get(action.data, 'data', [])

      const vData = gridData.map(data => {

        let locationSearchObj = {}
        locationSearchObj.locationId = data.locationId;
        locationSearchObj.supplierLocationName = data.supplierLocationName;
        
        return locationSearchObj;
      })
      return { ...state, locationSearch: vData };
    case clearModalData.type:
      return { ...state, locationSearch: null }  
    default:
      return state;
  }
}