import { oAuthConfig } from './configs/oAuthConfig.js';

const config = {
  appName: 'Materials Data Exchange (MatEx)',
  appFullName: 'Materials Data Exchange (MatEx)',
  oAuth: {
    client_id: 'nike.gsm.mdo-ui',
    responseType: 'id_token token',
    scopes: ['manufacture:mdo.curate.data::execute:','openid', 'profile', 'email', 'address', 'phone', 'offline_access'],
    redirectUri: window.location.origin,
    authorization: oAuthConfig.authorizationUrlProd,
    OktaTokenUrlProd: oAuthConfig.OktaTokenUrlProd
  }
};

export default config;