import { put, takeEvery, call, select } from "redux-saga/effects";
import { createServiceAction } from "../util/actionHelper.js";
import { apiConfig } from '../configs/apiConfig.js';
import { displayMessage, getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { getHasNextRequest, getLocationChange, getOffset, getPageNo, stopApiCalls } from "../reducers/authReducer.js";

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    const offset = yield select(getOffset);
    const nextPage = yield select(getPageNo);

    if (action.data) {
      const options = {
        locationID: action.data,
        pageNo: nextPage ? nextPage : 1,
        offset: offset ? offset : 0
      };

      const res = yield call(getRequest, apiConfig.materialSearchByLoc.endPointUrl, options);
      // Display HTTP status error message
      const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
      if (!statusErrorMessage) {
        const response = yield res.json();
        // Display server error message
        const serverErrorMessage = yield call(getServerValidationMessage, response);
        if (!serverErrorMessage && response.statusCode === 200) {
          yield put(createServiceAction(serviceType, 'success')(response));
          // Get hasNextRequest status
          const hasNextRequest = yield select(getHasNextRequest);
          const stopCall = yield select(stopApiCalls)
          const locationChange = yield select(getLocationChange)
          if (hasNextRequest === true && !stopCall && options.locationID === locationChange) {
            yield put(createServiceAction('materialSearchByLocLazyLoad', 'requested')(options.locationID));
          }
        }
        if (!serverErrorMessage && response.statusCode === 500) {
          const message = `${response.message}`;
          // Display error message
          yield put(createServiceAction(serviceType, 'dataNotFound')({}));
          yield call(displayMessage, 'error', message, 5000);
        }
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* materialSearchByLocSagas() {
  const serviceType = 'materialSearchByLocData';
  const serviceTypeLazyLoad = 'materialSearchByLocLazyLoad';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  yield takeEvery(createServiceAction(serviceTypeLazyLoad, 'requested').type, listener, serviceTypeLazyLoad);
}

export default materialSearchByLocSagas;