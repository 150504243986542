export function getUserGroup(openIdClaims){
    return openIdClaims.groups && openIdClaims.groups.map(value => {
      const grpInfoArr = value.split('.');
      let returnvalue ='';
      if(grpInfoArr[grpInfoArr.length-1]==='QA_vendorManagerRole' || grpInfoArr[grpInfoArr.length-1]==='vendorManager' ) {
        returnvalue = grpInfoArr[grpInfoArr.length-1]
       }
       else if(grpInfoArr[grpInfoArr.length-1]==='QA_partnerRole'|| grpInfoArr[grpInfoArr.length-1]==='partnerSME'){
        returnvalue = grpInfoArr[grpInfoArr.length-1]
       }
       else if(grpInfoArr[grpInfoArr.length-1]==='QA_adminRole'|| grpInfoArr[grpInfoArr.length-1]==='admin'){
        returnvalue = grpInfoArr[grpInfoArr.length-1]
      }
      else if (grpInfoArr[grpInfoArr.length-2]==='MaterialDataBulkImport'|| grpInfoArr[grpInfoArr.length-2]==='MaterialDataFileImport'){
        returnvalue = grpInfoArr[grpInfoArr.length-2]
      }
      return returnvalue
    })[0]
  }


const tokenBase = {
    get isExpired() {
      return ((this.expiresAt-300) * 1000) < Date.now();
    }
  };

const makeToken = token => Object.assign(Object.create(tokenBase), token);

function parseJwtPayload(payload) {
  return JSON.parse(window.atob(payload));
}

function unpackEncodedToken(authStates) {
    if (!authStates || !authStates.accessToken) return null;
  
    const accessValues = authStates.accessToken.accessToken.split('.');
    const openIdValues = authStates.idToken.idToken.split('.');
    const accessClaims = parseJwtPayload(accessValues[1]);
    const openIdClaims = parseJwtPayload(openIdValues[1]);
  
    // The name of these values will depend on the token issuer
    // Ping and Okta have different values for scope and username
    
    return {
      isAuthenticated: authStates.isAuthenticated,
      accessToken: authStates.accessToken.accessToken,
      openIdToken: authStates.idToken.idToken,
      expiresAt: accessClaims.exp,
      username: accessClaims.sub,
      email: openIdClaims.email,
      firstName: openIdClaims.given_name,
      lastName: openIdClaims.family_name,
      fullName: openIdClaims.name,
      scope: accessClaims.scope,
      group: getUserGroup(openIdClaims)
    };                       
  }

  export function getFullToken(authStates){

    let tokenInfo = unpackEncodedToken(authStates)
    tokenInfo= makeToken(tokenInfo)
    return tokenInfo
  }