import { clearModalData } from "../actions/authActions.js";
import { createServiceAction } from "../util/actionHelper.js";

const defaultState = {
  idLockerUsersAccess: [],
  searchCompleted: false
};

export default function idLockerUsersAccessReducer(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getIdLockerUsersAccess', 'requested').type:
      return state;
    case createServiceAction('getIdLockerUsersAccess', 'success').type:
      
      return { ...state, idLockerUsersAccess: action.data.data };
    case createServiceAction('getIdLockerUsersAccess', 'error').type:
      return { ...state, idLockerUsersAccess: [] };
    case clearModalData.type:
      return { ...state, idLockerUsersAccess: null };
    default:
      return state;
  }
}