import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';
import { clearModalData } from "../actions/authActions.js";

const defaultState = {
  vendorGridData: [],
};

export default function vendorGridData(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('vendorGridData', 'requested').type:
      return state;
    case createServiceAction('vendorGridData', 'success').type:
      const gridData = get(action.data, 'data', [])
      const vData = gridData.map(data => {
        let vendorObj = {}
        let date =new Date(data.lastSubmittedDate)
        let dd = date.getDate()
        if(dd<10){
          dd = '0' + dd
        }
        let mm = date.getMonth() + 1
        if(mm<10){
          mm = '0' + mm
        }
        vendorObj.vendor_id = data.vendor_id
        vendorObj.vendorGroup = data.vendorGroup
        vendorObj.vendorName = data.name;
        vendorObj.address1 = data.physicalAddress.address1;
        vendorObj.address2 = data.physicalAddress.address2;
        vendorObj.address3 = data.physicalAddress.address3;
        vendorObj.area = data.physicalAddress.area;
        vendorObj.city = data.physicalAddress.city;
        vendorObj.state = data.physicalAddress.state;
        vendorObj.postalCode = data.physicalAddress.postalCode;
        vendorObj.county = data.physicalAddress.county;
        vendorObj.lastsubmitteddatevendor = mm+"-"+dd+"-"+date.getFullYear();
        vendorObj.endpointsourcevendor = data.endPointSource;
        vendorObj.mco = data.physicalAddress.country;
        vendorObj.status = data.status===false?"Matching vendor record not found":null
        return vendorObj;
      })
      return { ...state, vendorGridData: vData };
      case createServiceAction("vendorGridDataLazyLoad", "requested").type:
      return { ...state, vendorGridData: [...state.vendorGridData] };
      case createServiceAction("vendorGridDataLazyLoad", "success").type:
        const lVendorgridData = get(action.data, 'data', [])
        const lVendorData = lVendorgridData.map(data => {
          let vendorObj = {}
          let date =new Date(data.lastSubmittedDate)
          let dd = date.getDate()
          if (dd < 10) {
            dd = '0' + dd
          }
          let mm = date.getMonth() + 1
          if (mm < 10) {
            mm = '0' + mm
          }
          vendorObj.vendor_id = data.vendor_id
          vendorObj.vendorGroup = data.vendorGroup
          vendorObj.vendorName = data.name;
          vendorObj.address1 = data.physicalAddress.address1;
          vendorObj.address2 = data.physicalAddress.address2;
          vendorObj.address3 = data.physicalAddress.address3;
          vendorObj.area = data.physicalAddress.area;
         vendorObj.city = data.physicalAddress.city;
        vendorObj.state = data.physicalAddress.state;
        vendorObj.postalCode = data.physicalAddress.postalCode;
        vendorObj.county = data.physicalAddress.county;
        vendorObj.lastsubmitteddatevendor = mm+"-"+dd+"-"+date.getFullYear();
        vendorObj.endpointsourcevendor = data.endPointSource;
          vendorObj.mco = data.physicalAddress.country;
          vendorObj.status = data.status===false?"Matching vendor record not found":null
          return vendorObj;
        })
        return { ...state, vendorGridData: [...state.vendorGridData, ...lVendorData] };
      case clearModalData.type:
        return { ...state, vendorGridData: null };
    default:
      return state;
  }
}