import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';
import { clearModalData } from "../actions/authActions.js";

const defaultState = {
  vendorFetch: null
};

export default function gridVendorFetch(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('vendorFetch', 'requested').type:
      return state;
    case createServiceAction('vendorFetch', 'success').type:
      const gridData = get(action.data, 'data', [])
      const vData = gridData[0].map(data => {
        let fetchObj = {}
        fetchObj.supplierName = data.name;
        fetchObj.supplierID = data.vendorGroupCode;
        fetchObj.supplierAddress = data.address1;
        fetchObj.supplierCountry = data.mco;
        return fetchObj;
      })
      return { ...state, vendorFetch: vData };
    case clearModalData.type:
      return { ...state, vendorFetch: null }
    default:
      return state;
  }
}

