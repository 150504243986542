import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { displayMessage, getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';


export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    if (action.data) {
      const options = {
        name: `${action.data}*`,
      };

      const res = yield call(getRequest, apiConfig.materialVendorSearch.endPointUrl, options);
      // Display HTTP status error message
      const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
      if (!statusErrorMessage) {
        // Parse response object
        const response = yield res.json();
        // Display server error message
        const serverErrorMessage = yield call(getServerValidationMessage, response);
        if (!serverErrorMessage && response.statusCode === 200) {
          yield put(createServiceAction(serviceType, 'success')(response));
        }
        if (!serverErrorMessage && response.statusCode === 500) {
          const message = `${response.message}`;
          // Display error message
          yield put(createServiceAction(serviceType, 'success')({}));
          yield call(displayMessage, 'error', message, 5000);
        }
      }
    }
    else {
      yield put(createServiceAction(serviceType, 'success')({}));
    }
  } catch (e) {

    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* materialVendorSearchSagas() {
  const serviceType = 'materialVendorSearch';


  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}
export default materialVendorSearchSagas;