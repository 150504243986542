import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest } from '../util/httpRequest.js';
import { displayMessage } from '../util/messageHelper.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage} from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import { selectedButton } from '../actions/authActions.js';


export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    let options = {};
    options.material_id = action.data.material_id;
    options.pcxId = action.data.pcxMaterialId ? action.data.pcxMaterialId.toString() : "";
    options.pcxSupplierLocationID = action?.data?.pcxSupplierLocationID;
    options.suppliedMaterialId = action.data.pcxSuppliedId;
    options.catalogId = action.data.catalogId?action.data.catalogId:"";
    options.description = action.data.description?action.data.description:"";
    options.user_id= action.data.userId?action.data.userId:"";
    options.pdmId = action.data.pdmNumber?action.data.pdmNumber:"";
    options.ppsId = action.data.ppsNumber?action.data.ppsNumber.toString():"";
    options.vendorName = action.data.vendorsName;
    options.locationName = action.data.locationsName;
    options.clientId = action.data.clientId;
    const path = {
      history: null
    };
    const routes = pick(action.data, keys(path));
    const res = yield call(putRequest, apiConfig.updateMaterial.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){  
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const message = `${response.message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
        yield put(selectedButton('Material'))
        routes.history.push({
          pathname: "/mapping-data"
        });
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* updateMaterialSagas() {
  const serviceType = 'updateMaterial';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default updateMaterialSagas;       




