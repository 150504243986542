import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { displayMessage, getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call} from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    const options = {...action.data};
    const res = yield call(getRequest, apiConfig.poVendorSearch.endPointUrl, options);
    // Display HTTP status error message

    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){ 
      
      const response = yield res.json();
     
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);     
       
        yield put(createServiceAction(serviceType, 'success')(response));
        if(!serverErrorMessage && response.statusCode !== 200){
          yield put(createServiceAction(serviceType, 'error')());
          const message = response.message?`${response.message}`:`${response.msg.message}`;
          yield call(displayMessage, 'error', message, 5000);
        }
    }
    else{
      yield put(createServiceAction(serviceType, 'error')())
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* poVendorSearchSagas() {
  const serviceType = 'poVendorSearch';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default poVendorSearchSagas;
