import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { displayMessage, postRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    if (action.data) {
      const payload = {
        name: action.data.name,
        type: action.data.type || action.data.type === "vendorLegalName" ? "vendorLegalName" : "manufacturingLocationName"
      }
      const res = yield call(postRequest, apiConfig.vendorSearch.endPointUrl, payload);
      // Display HTTP status error message
      const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
      if (!statusErrorMessage) {
        // Parse response object
        const response = yield res.json();
        // Display server error message
        const serverErrorMessage = yield call(getServerValidationMessage, response);
        if (!serverErrorMessage && response.statusCode === 200) {
          yield put(createServiceAction(serviceType, 'success')(response));
        }
        if (!serverErrorMessage && response.statusCode === 500) {
          const message = `${response.message}`;
          // Display error message
          yield call(displayMessage, 'error', message, 5000);
        }
      }
    }
    else {
      yield put(createServiceAction(serviceType, 'success')({}));
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* vendorSearchSagas() {
  const serviceType = 'vendorSearch';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}
export default vendorSearchSagas;
