import { createAction } from '../util/index.js';

export const startAuthCheck = createAction('AUTH_CHECK_START', { noPayload: true });
export const finishAuthCheck = createAction('AUTH_CHECK_COMPLETE', { noPayload: true });
export const setAuthCheckError = createAction('AUTH_CHECK_ERROR');

export const setUserLogin = createAction('LOGIN_SET_USER');
export const setUserLogout = createAction('LOGOUT_SET_USER');
export const checkUserExist = createAction('CHECK_USER_EXIST');
export const checkValidGroup = createAction('CHECK_VALID_GROUP')
export const requestUserLogin = createAction('LOGIN_USER_REQUESTED');
export const requestUserLogout = createAction('LOGOUT_USER_REQUESTED');

export const authCheckRequested = createAction('AUTH_CHECK_REQUESTED', { noPayload: true });

export const locationReinitilized = createAction('LOCATION_REINITILIZED', { noPayload: true });
export const materialReinitilized = createAction('MATERIAL_REINITILIZED', { noPayload: true });
export const clearModalData = createAction('CLEAR_MODAL_DATA');
export const cancelCalls = createAction('CANCEL_CALL', requestObject => requestObject);
export const selectedButton = createAction('SELECTED_BUTTON', requestObject => requestObject);
export const locationChange = createAction('LOCATION_CHANGE', requestObject => requestObject);
export const vendorChange = createAction('VENDOR_CHANGE', requestObject => requestObject);
export const columnChange = createAction('COLUMN_CHANGE', requestObject => requestObject);

export const uploadActions = createAction('UPLOAD_ACTION', requestObject => requestObject);
export const poActions = createAction('PO_ACTION', requestObject => requestObject);
export const adminActions = createAction('ADMIN_UPLOAD', requestObject => requestObject);