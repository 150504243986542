import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';
import { clearModalData } from "../actions/authActions.js";

const defaultState = {
  vendorSearch: null,
};

export default function gridVendorSearch(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('vendorSearch', 'requested').type:
      return state;
    case createServiceAction('vendorSearch', 'success').type:
      const gridData = get(action.data, 'data', [])
      const vData = gridData[0].map(data => {
        let vendorSearchObj = {}
        vendorSearchObj.vendorIdentifier = data.vendorIdentifier;
        vendorSearchObj.vendorGroupCode = data.vendorGroupCode;
        vendorSearchObj.manufacturingLocationIdentifier = data.manufacturingLocationIdentifier;
        vendorSearchObj.vendorName = data.name;
        vendorSearchObj.vendorAddress = data.address1;
        vendorSearchObj.legacySupplierCode = data.legacyCode;
        vendorSearchObj.factoryCode = data.factoryCode;
        vendorSearchObj.address2 = data.address2;
        vendorSearchObj.address3 = data.address3;
        vendorSearchObj.address4 = data.address4;
        vendorSearchObj.address5 = data.address5;
        vendorSearchObj.city = data.city;
        vendorSearchObj.stateProvinceDesc = data.stateProvinceDesc;
        vendorSearchObj.mfglocationname= data.manufacturingLocationName;
        vendorSearchObj.mco = data.mco;
        return vendorSearchObj
      })
      return { ...state, vendorSearch: vData };
    case clearModalData.type:
      return { ...state, vendorSearch: null }
    default:
      return state;
  }
}