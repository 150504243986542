import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';
import { isEmpty } from "../lodash.index.js";

const defaultState = {
  userGridData: [],
};

export default function userDetails(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('userDetails', 'requested').type:
      return { ...state, userGridData: null };
    case createServiceAction('userDetails', 'success').type:
      const gridData = get(action.data, 'data', [])
      const uData = gridData.map(data => {
        let userObj = {}
        userObj.name = data.userName
        userObj.email = data.email;
        userObj.userType = (data.userType === "QA_adminRole" || data.userType === "admin") ? "ADMIN"
          : (data.userType === "QA_partnerRole" || data.userType === "partnerSME") ? "PARTNER"
            : (data.userType === "MaterialDataFileImport" || data.userType === "MaterialDataBulkImport") ? "PARTNER(SA)" : "VENDOR-MANAGER";
        userObj.contact = data.contact;
        let suppName = !isEmpty(data.vendors) ? data.vendors.map((supp) => {
          return supp.supplierName
        }) : null
        let groups = data?.groupClientID?.map(item => {
          return item.clientId?.map(x => `${item?.vendorGroup} -- ${x}`)?.join(",   ")
        })
        let groupsSA = data?.groupClientIDSA?.map(item => {
          return item.location && item.location?.map(x => `${item?.vendorGroup} -- ${x?.split("--")[1] !== "null" ? x?.split("--")[1] : "default"}`)?.join(",   ")
        })
        userObj.supplierName = data.vendorAction ? [data.vendorAction] : suppName;
        userObj.vendors = data.vendors;
        userObj.endpoint = data?.endpoint ? data?.endpoint?.filter(Boolean) : []
        userObj.isChecked = data.vendorAction === "All Vendors" ? true : false
        userObj.isUnChecked = data.vendorAction === "No Vendor" ? true : false
        userObj.vendorAction = data.vendorAction;
        userObj.groupName = groups ? [...new Set(groups?.filter(Boolean))] : data?.groups ? [...new Set(data?.groups?.filter(Boolean))] : []
        userObj.groupNameSA = groupsSA && data.userType === "MaterialDataFileImport" ? [...new Set(groupsSA?.filter(Boolean))] : data?.groupsSA ? [...new Set(data?.groupsSA?.filter(Boolean))] : []
        userObj.groupClientID = data?.groupClientID;
        userObj.groupClientIDSA = data?.groupClientIDSA;
        userObj.activeStatus = data.isActive;
        userObj.userId = data.userId;
        return userObj;
      })
      return { ...state, userGridData: uData };
    default:
      return state;
  }
}