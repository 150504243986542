import { clearModalData } from "../actions/authActions.js";
import { createServiceAction } from "../util/actionHelper.js";

const defaultState = {
  idLockerUsers: null,
  searchCompleted: true
};

export default function idLockerUsersReducer(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getIdLockerUsers', 'requested').type:
      return { ...state, idLockerUsers: null, searchCompleted: false };
    case createServiceAction('getIdLockerUsers', 'reset').type:
      return { ...state, idLockerUsers: null, searchCompleted: true };
    case createServiceAction('getIdLockerUsers', 'success').type:
      return { ...state, idLockerUsers: action?.data?.data?.map(x => ({ ...x, manager: x.Manager })), searchCompleted: true };
    case createServiceAction('getIdLockerUsers', 'error').type:
      return { ...state, idLockerUsers: [], searchCompleted: true };
    case clearModalData.type:
      return { ...state, idLockerUsers: null, searchCompleted: true };
    default:
      return state;
  }
}