import { createServiceAction } from "../util/actionHelper.js";
import { get } from "../lodash.index.js";

const defaultState = {
  uploadedFilesList: [],
};

export default function uploadedFilesList(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getUploadedFiles', 'requested').type:
      return { ...state, uploadedFilesList: null };;
    case createServiceAction('getUploadedFiles', 'success').type:
      const uploadedFilesList = get(action.data, 'data', [])
      const uData = uploadedFilesList.map(data => {
        return {
          fileName: data?.original_file_name,
          status: data?.status === "true" ? "Success" : data?.status === "false" ? "Failed" : "Processing",
          updatedAt: data?.updatedAt,
          original_file_name: data?.original_file_name,
          response_file: data?.response_file,
          id: data?.id,
          domain: data?.domain,
          vendor_name: data?.vendor_name,
          lastSubmission: data?.lastSubmission,
          lastSuccessSubmission: data?.lastSuccessSubmission
        }
      })
      return { ...state, uploadedFilesList: uData };
    case createServiceAction('getUserFiles', 'requested').type:
      return { ...state, uploadedFilesList: null };;
    case createServiceAction('getUserFiles', 'success').type:
      const userFilesList = get(action.data, 'data', [])
      const gData = userFilesList.map(data => {
        return {
          fileName: data?.original_file_name,
          status: data?.status === "true" ? "SUCCESS" : data?.status === "false" ? "FAILED" : data?.status,
          updatedAt: data?.updatedAt,
          original_file_name: data?.original_file_name,
          response_file: data?.response_file,
          id: data?.id,
        }
      })
      return { ...state, uploadedFilesList: gData };
    default:
      return state;
  }
}