import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest } from '../util/httpRequest.js';
import { displayMessage } from '../util/messageHelper.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage} from '../services/serverValidations.js';
import { put, takeEvery, call} from 'redux-saga/effects';
import keys from 'lodash/keys';
import pick from 'lodash/pick';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    let options = {};
    options.color_id = action.data.color_id;
    options.user_id = action.data.user_id;
    options.id = action.data.colorId?action.data.colorId:"";
    options.name = action.data.name?action.data.name:"";
    options.colorBrand = action.data.brand?action.data.brand:"Nike";
    options.description = action.data.description?action.data.description:"";  
    options.catalogItemId = action.data.catalogItemId;  
    options.clientId = action.data.clientId;
    options.colorReferenceKey = action.data.colorReferenceKey;
    const path = {
      history: null
    };
    const routes = pick(action.data, keys(path));
    
    const res = yield call(putRequest, apiConfig.colorUpdate.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){  
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const message = `${response.message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
        routes.history.push({
          pathname: "/mapping-data"
        });
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* updateColorSagas() {
  const serviceType = 'updateColor';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default updateColorSagas;