import { displayMessage } from '../util/index.js';

export function getHTTPStatusValidationMessage(apiResp, waitingTime){
    let message = '';
    // Unauthorized error message
    if([401, 403, '401', '403'].includes(apiResp.status)){
        message = 'Your session token has expired or is invalid. Please refresh the page and try again';
    // Internal server error
    } else if(apiResp.status === 500){
        message = 'The application has encountered an unknown error. Please retry after sometime';
    }
    else if (apiResp.status === 504){
        message = 'Endpoint request timed out';
    }
    // display error message on view
    if(message){
        if(waitingTime){
            setTimeout(function(){ displayMessage('error', message, 5000); }, waitingTime);
        } else {
            displayMessage('error', message, 5000);
        }
    }
    return message;
}


export function getServerValidationMessage(response, waitingTime){
    let message = '';
    if(response.statusCode===400){
        // Handled error
        if(response.message){
            message = response.message;
        // unhandled errors like DB connecttion issue etc
        } else if(response.errorMessage){
            message = 'Oops! Something went wrong! Please retry after sometime';
        // All other error scenarios which has an errorcode value
        } else {
            message = 'The application has encountered an unknown error. Please retry after sometime';
        }
    } else {
        const keys = Object.keys(response);
        if (keys.length === 1 && keys[0] === 'message'){
            message = response.message;
        }
    }
    // Response with message value as blank is considered as error
    if(response.message === ''){
        message = 'The application has encountered an unknown error. Please retry after sometime';
    }
    // display error message on view
    if(message){
        if(waitingTime){
            setTimeout(function(){ displayMessage('error', message, 5000); }, waitingTime);
        } else {
            displayMessage('error', message, 5000);
        }
    }
    return message;
}