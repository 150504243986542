
export function convertDateToDBFormat(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export function convertDateReview(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("-");
}


export function convertDate(str) {
  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str)) {
    return str.substring(0, str.indexOf("T")).split('-').reverse().join('-');
  }
  return '';
}


const monthToComparableNumber = (date) => {
  if (date === undefined || date === null || date.length < 10) {
    return null;
  }

  const last = date.length;

  const yearNumber = Number.parseInt(date.substring(6, 10));
  const dayNumber = Number.parseInt(date.substring(3, 5));
  const monthNumber = Number.parseInt(date.substring(0, 2));

  const partOfDay = date.substring(last - 2, last) === 'PM' ? 0.12 : 0;
  const strHour = String(Number(date.substring(last - 7, last - 5)));

  const hour =
    strHour.length === 2 ? Number('0.' + strHour) : Number('0.0' + strHour);
  const h24 = partOfDay + hour;

  const minutes = Number('0.00' + date.substring(last - 4, last - 2));

  return yearNumber * 10000 + monthNumber * 100 + dayNumber + h24 + minutes;
};

export const dateComparator = (date1, date2) => {
  const date1Number = monthToComparableNumber(date1);
  const date2Number = monthToComparableNumber(date2);

  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  return date1Number - date2Number;
}; 

export const dateFormatUTC = (date) => {
  if (date !== null && date !== undefined) {
    date = new Date(date)
    let dd = date.getUTCDate()
    if (dd < 10) {
      dd = '0' + dd
    }
    let mm = date.getUTCMonth() + 1
    if (mm < 10) {
      mm = '0' + mm
    }
    return mm + "-" + dd + "-" + date.getUTCFullYear()
  }
  return null
}