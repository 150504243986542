import React from 'react';

import { NotFound, H1 } from '../index.js';

export const InvalidRoute = () => (
  <div className="ncss-container">
    <H1 className="align-center u-bold h1">404</H1>
    <NotFound />
  </div>
);
