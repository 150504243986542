import { clearModalData } from "../actions/authActions.js";
import { createServiceAction } from "../util/actionHelper.js";

const defaultState = {
  requestList: null,
  dataForDownload: []
};

export default function idLockerRequestListReducer(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getIdLockerRequestList', 'requested').type:
      return state;
    case createServiceAction('getIdLockerRequestList', 'success').type:
      const filterData = action?.data?.data?.map(data => (data?.userType !== "ADMIN" && data?.operation === "add" &&
      !data?.isMdoOnboarded && ["Approved","Success"]?.includes(data?.requestState)) && ([data?.userDisplayName, data?.email, data?.userType]))?.filter(Boolean)
      return {
        ...state,
        requestList: action?.data?.data.map(data => ({ ...data, isMdoOnboarded: data?.isMdoOnboarded ? "Onboarded" : "Not Onboarded", operation: data?.operation === "add" ? "Add" : "Remove" })),
        dataForDownload: filterData?.length? filterData:null
      };
    case createServiceAction('getIdLockerRequestList', 'error').type:
      return { ...state, requestList: [] };
    case clearModalData.type:
      return { ...state, requestList: null, dataForDownload: null };
    default:
      return state;
  }
}