import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';

const defaultState = {
  colorMappingGridData: [],
};

export default function colorMappingGridDataReducer(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('colorMappingDetails', 'requested').type:
      return state;
    case createServiceAction('colorMappingDetails', 'success').type:
      const gridData = get(action.data, 'data', [])
      const uData = gridData.map(data => {
        let date = new Date(data?.updatedAt)
          let dd = date.getDate()
          if (dd < 10) {
            dd = '0' + dd
          }
          let mm = date.getMonth() + 1
          if (mm < 10) {
            mm = '0' + mm
          }
        let obj = {
          catalogItemId: data?.catalogItemId,
          failed_color_id: data?.failed_color_id,
          nikeColorId: data?.dataId,
          updatedBy: data?.updatedBy,
          nikeColorCode: data?.colorCode,
          mappingDate: mm+"-"+dd+"-"+date.getFullYear(),
          nikeColorName: data?.name,
          nikeColorBrand: data?.colorBrand,
          nikeColorDesc: data?.description,
          status: data?.status
        };
        return obj;
      })
      return { ...state, colorMappingGridData: uData };
    default:
      return state;
  }
}