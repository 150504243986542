import { clearModalData } from "../actions/authActions.js";
import { createServiceAction } from "../util/actionHelper.js";

const defaultState = {
  accessList: null
};

export default function idLockerUserAccessListReducer(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getAllAccessForIdlockerUser', 'requested').type:
      return {...state, accessList: null};
    case createServiceAction('getAllAccessForIdlockerUser', 'success').type:
      return { ...state, accessList: (action?.data?.data || []) };
    case createServiceAction('getAllAccessForIdlockerUser', 'error').type:
      return { ...state, accessList: [] };
    case clearModalData.type:
      return { ...state, accessList: null };
    default:
      return state;
  }
}