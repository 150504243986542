import { createServiceAction } from '../util/actionHelper.js';


const defaultState = {
    notificationDetails: [],
};


export default function lab(state = defaultState, action) {
    switch (action.type) {
        case createServiceAction('listNotification', 'requested').type:
            return { ...state, notificationDetails: null };
        case createServiceAction('listNotification', 'success').type:
            return {
                ...state, notificationDetails: action.data.data,
                allUnreaded: action.data.data ? action.data.data.filter(e => !e.isRead) : []
            };
        default:
            return state;
    }
}


