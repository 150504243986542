import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { PageSpinner } from '../../components/index.js';
import { requestUserLogout } from '../../actions/authActions.js';
import { userLoggedIn } from '../../reducers/authReducer.js';

const mapStateToProps = state => ({
  isLoggedIn: userLoggedIn(state)
});

const LogoutUser = withRouter(
  connect(
    mapStateToProps,
    { requestUserLogout }
  )(({ isLoggedIn, requestUserLogout }) => {
    if (isLoggedIn) requestUserLogout({ skipRedirect: false });
    return <PageSpinner />;
  })
);

export default LogoutUser;
