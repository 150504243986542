import { clearModalData } from "../actions/authActions.js";
import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';
import { dateFormatUTC } from "../util/dateHelper.js";

const defaultState = {
  poGridData: null,
};

export default function poItemGrid(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getPOItem', 'requested').type:
      return state;
    case createServiceAction('getPOItem', 'success').type:
      const gridData = get(action.data, 'data', [])
      const poData = gridData.map(data => {
        let poObj = {}
        poObj.lineId = data.order_item_no;
        poObj.lineStatePoSubmit = dateFormatUTC(data?.submit_last_update);
        poObj.lineStatePoConfirm = dateFormatUTC(data?.confirm_last_update);
        poObj.lineStateShipNotification = dateFormatUTC(data?.shipment_notification_last_update);
        poObj.lineStateShipReciept = dateFormatUTC(data?.shipment_receipt_last_update);
        poObj.poLineStatus = data.po_line_status;
        poObj.catalogId = data.catalog_id;
        poObj.nikeMaterialId = data.material_id;
        poObj.quantity = data.quantity;
        poObj.requestedShipDate = dateFormatUTC(data?.requested_ship_date);
        poObj.requestedDeliveryDate = dateFormatUTC(data?.requested_delivery_date);
        poObj.reason = ((data?.partialreceiptquantity === null || data?.partialreceiptquantity === "0" || data?.partialreceiptquantity === undefined) && 
        (data?.partial_notify_quantity === null || data?.partial_notify_quantity === "0" || data?.partial_notify_quantity === undefined)) ? 
        "" : `Quantity Mismatch: From order quantity: ${data.quantity}, received  ${(data?.partialreceiptquantity === null || data?.partialreceiptquantity === "0" || data?.partialreceiptquantity === undefined)? `${Number(data.quantity)>Number(data?.partial_notify_quantity)?"partial":"higher"} shipment notification quantity: ${data?.partial_notify_quantity}.`:`${Number(data.quantity)>Number(data?.partialreceiptquantity)?"partial":"higher"} shipment receipt quantity: ${data?.partialreceiptquantity}.`}`;
        return poObj;
      })
      return { ...state, poGridData: poData };
    case createServiceAction('getPOItem', 'error').type:
      return { ...state, poGridData: [] };
    case clearModalData.type:
      return { ...state, poGridData: null };
    default:
      return state;
  }
}
