import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';

export class renderYearSelector extends Component {
    validation = (currentDate) => {
        const { minDate } = this.props;
        return currentDate.isAfter(moment(minDate));
    };
    renderInput(props, openCalendar) {
        return (
            <div className="calender-input">
                <input type="number" {...props} />
                <span className="calender-icon" onClick={openCalendar}><i class="fas fa-calendar-alt"></i></span>
            </div>
        );
    };
    renderYear( props, year, selectedDate ){
    
        return <td {...props}>{ year }</td>;
        
    }
   
    render() {
        const { input } = this.props;
        const inputProps = { disabled: true };

        return (

            < div >
                <Datetime
                    value={input.value || new Date()}
                    renderInput={this.renderInput}
                    renderYear={ this.renderYear }
                    inputProps={inputProps}
                    isValidDate={this.validation}
                    dateFormat="YYYY"
                    onChange={(date) => input.onChange(date)}
                    closeOnSelect={true}
                    disableCloseOnClickOutside={false}
                />
            </div >
        )
    }

}

renderYearSelector.propTypes = {
    input: PropTypes.object,
    minDate: PropTypes.bool
};

export default renderYearSelector;