import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';


export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    if (action.data) {
      const payload = {
      name : action.data,
      type: "all"
  }      
      const res = yield call(postRequest, apiConfig.vendorSearch.endPointUrl, payload);
      // Display HTTP status error message
      const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
      if(!statusErrorMessage){  
        // Parse response object
        const response = yield res.json();
        // Display server error message
        const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.statusCode === 200) {
          yield put(createServiceAction(serviceType, 'success')(response));
       }
      }
    } 
    else {
      yield put(createServiceAction(serviceType, 'success')({}));
    }
  } catch (e) {
     
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* vendorFetchSagas() {
    const serviceType = 'vendorFetch';
   
  
    yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
  }
export default vendorFetchSagas;