import React from 'react';
import { Route, BrowserRouter as Router, useHistory } from 'react-router-dom';
import { OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import PropTypes from 'prop-types';

import appHistory from '../util/history.js';
import { oidc } from '../configs/oAuthConfig.js';
import Login from '../views/auth/login.js'
import UserNotExistsError from '../views/Generic/UserNotExistsError.js';



const Routes = ({ history = appHistory }) => {
  const oktaAuth = new OktaAuth(oidc);
   let apHistory = useHistory();

  const restoreOriginalUri =  (_oktaAuth, originalUri) => {
    
    apHistory.replace(toRelativeUrl('/', window.location.origin));
  };

  
  
  try {
    if (localStorage.getItem('okta-token-storage') && JSON.parse(localStorage.getItem('okta-token-storage')).idToken && JSON.parse(localStorage.getItem('okta-token-storage')).idToken.expiresAt && (new Date() > new Date(JSON.parse(localStorage.getItem('okta-token-storage')).idToken.expiresAt * 1000))){
      localStorage.removeItem('okta-cache-storage')
      localStorage.removeItem('okta-token-storage')
      sessionStorage.setItem("token-satus", "expired");
    }
    if (window.performance) {
      if (performance.navigation.type === 1) {
        history = appHistory
      }
      else {
        history = appHistory
      }
    }
    const implicitCallback = () => (<LoginCallback errorComponent={UserNotExistsError} />); 

    return (
    
       
         <Security 
         oktaAuth={oktaAuth} 
         restoreOriginalUri={restoreOriginalUri}
         >
           
              <Route path="/implicit/callback" component={implicitCallback}/>
              <SecureRoute path="/" component={Login} />
              
            </Security> 
      
     
    );
  }
  catch (e) {
    return ( 
      <Router history={history}>
         <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Route path="/implicit/callback" component={LoginCallback} />
            <SecureRoute path="/" component={Login} />
          </Security> 
    </Router>

    );
  }
};

Routes.propTypes = {
  component: PropTypes.func,
  history: PropTypes.func
};
export {Routes}
export default Routes;
