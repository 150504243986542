import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';
import { clearModalData } from "../actions/authActions.js";

const defaultState = {
  poVendorSearch: null
};

export default function gridPoVendorSearch(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('poVendorSearch', 'requested').type:
      return defaultState;
    case createServiceAction('poVendorSearch', 'success').type:
      const gridData = get(action.data, 'data', [])
      const poVendorSearch = gridData.map(data => {
        const fetchObj = {
          vendorName: data.name,
          vendorAddress: data.address,
        }
        return fetchObj;
      })
      return { ...state, poVendorSearch };
    case clearModalData.type:
      return { ...state, poVendorSearch: [] }
    case createServiceAction('poVendorSearch', 'error').type:
      return { ...state, poVendorSearch: [] };
    default:
      return state;
  }
}