import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';

import '../stylus/app.css';
import '../stylus/app.css';
import '../stylus/addMaterial.css';
import '../stylus/addSupplier.css';
import '../stylus/alertify.css';
import '../stylus/chip.css';
import '../stylus/collapse.css';
import '../stylus/commentModal.css';
import '../stylus/core.css';
import '../stylus/customTable.css';
import '../stylus/dashboard.css';
import '../stylus/datePicker.css';
import '../stylus/donut.css';
import '../stylus/edit.css';
import '../stylus/footer.css';
import '../stylus/forms.css';
import '../stylus/frf.css';
import '../stylus/grid.css';
import '../stylus/header.css';
import '../stylus/list.css';
import '../stylus/loader.css';
import '../stylus/markdown.css';
import '../stylus/menu.css';
import '../stylus/modal.css';
import '../stylus/navbar.css';
import '../stylus/ncss.css';
import '../stylus/page.css';
import '../stylus/progressBar.css';
import '../stylus/progressTracker.css';
import '../stylus/qrf.css';
import '../stylus/select.css';
import '../stylus/spinner.css';
import '../stylus/testDetail.css';
import '../stylus/toggle.css';
import '../stylus/tooltip.css';
import '../stylus/trf.css';
import '../stylus/variables.css';
import '../stylus/videoPlayer.css';

import config from '../config.js';
import Header from '../views/header/header.jsx';
import SlideMenuContainer from '../views/navigation/SlideMenuContainer.jsx';
import { navMenuclose } from '../actions/navigationActions.js';
import OfflineBanner from '../views/offline/offline.jsx';
import { LoginRequired } from '../views/auth/requireLogin.js';
import NotificationsMessage from '../components/notifications/NotificationsMessage';
import Footer from '../views/footer/footer';

const App =( { children, location, navRoutes })=> {  
    const dispatch = useDispatch();
    const navLinks = navRoutes.map(r => ({
      to: r.path,
      label: r.title,
      icon: r.icon,
      heading: r.heading,
      id: r.id,
      url: r.url || ''
    }));
    return (
      <DocumentTitle title={config.appName}>
        <div className="u-full-height">
          <OfflineBanner />
          <Header 
            isErrorRoute={(location.pathname && location.pathname.indexOf('/error') === -1) ? false : true} 
          />
          <NotificationsMessage />
          <SlideMenuContainer
            title={config.appName}
            description={config.appFullName}
            navLinks={navLinks}
            onClose={() => dispatch(navMenuclose())}
          >
            <div className="app-container">
              <LoginRequired>{children}</LoginRequired>
            </div>
          </SlideMenuContainer>
          <div className="footer">
            <Footer />
          </div>
        </div>
      </DocumentTitle>
    );
  
}

App.propTypes = {
 // authCheckRequested: PropTypes.func,
  navMenuclose: PropTypes.func,
  children: PropTypes.object,
  loginFinished: PropTypes.bool,
  loginError: PropTypes.bool
};

export { App };
export default withRouter(
 (App)
);