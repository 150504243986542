import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';

const defaultState = {
  materialMappingGridData: [],
  materialMap: null
};

export default function editMaterialMapping(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('materialMappingDetailsAll', 'requested').type:
      return { ...state, materialMappingGridData: null, materialMap: "All" };
    case createServiceAction('materialMappingDetailsAll', 'success').type:
      const gridDataAll = get(action.data, 'data', [])
      const uDataAll = gridDataAll.map(data => {
        let date = new Date(data?.updatedAt)
        let dd = date?.getDate()
        if (dd < 10) {
          dd = '0' + dd
        }
        let mm = date?.getMonth() + 1
        if (mm < 10) {
          mm = '0' + mm
        }
        let obj = {
          id: data?.id,
          nikeMaterialID: data?.nikeMaterialID,
          parentMaterialID: data?.pcxId,
          pcxSupplierLocationID: data?.pcxSupplierLocationID,
          submittedID: data?.catalogId,
          pdmMaterialID: data?.pdmId,
          ppsMaterialID: data?.ppsId,
          nikeMaterialDesc: data?.description,
          status: data?.status,
          vendorName: data?.vendorName,
          mappingDate: data?.updatedAt ? mm + "-" + dd + "-" + date?.getFullYear() : null,
          locationName: data?.locationName
        };
        return obj;
      })
      if (state.materialMap && state.materialMap === "All") {
        return { ...state, materialMappingGridData: uDataAll };
      }
      break;
    case createServiceAction('materialMappingDetails', 'requested').type:
      return { ...state, materialMappingGridData: null, materialMap: "Others" };
    case createServiceAction('materialMappingDetails', 'success').type:
      const gridData = get(action.data, 'data', [])
      const uData = gridData.map(data => {
        let date = new Date(data?.updatedAt)
        let dd = date?.getDate()
        if (dd < 10) {
          dd = '0' + dd
        }
        let mm = date?.getMonth() + 1
        if (mm < 10) {
          mm = '0' + mm
        }
        let obj = {
          id: data?.id,
          nikePdmID: data?.nikeMaterialID,
          suppliedMaterialID: data?.pcxId,
          submittedID: data?.catalogId,
          locationID: data?.locationId,
          status: data?.status,
          vendorName: data?.vendorName,
          mappingDate: data?.updatedAt ? mm + "-" + dd + "-" + date?.getFullYear() : null,
          locationName: data?.locationName
        };
        return obj;
      })
      if (state.materialMap && state.materialMap === "Others") {
        return { ...state, materialMappingGridData: uData };
      }
      break;
    default:
      return state;
  }
}
