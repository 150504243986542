import { store } from '../index.js';
import { selectUserDetails } from '../reducers/authReducer.js';

const globalHeader = () => {
  const { userId, group, labLocationId, accessToken } = selectUserDetails(store.getState());
  return {
    user_id: userId,
    user_type: group,
    lab_location_id: labLocationId,
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    'pragma': 'no-cache',
    'cache-control': 'no-cache'
  };
};

export default globalHeader;
