import { createServiceAction } from "../util/actionHelper.js";
import {groupBy, get} from "../lodash.index.js";


const defaultState = {
  groupListData: [],
  listByGroupName: [],
  consolidatedGroupNames: [],
  groupNameDataSet:null
};

export default function groupListData(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('getGroupList', 'requested').type:
      return state;
    case createServiceAction('getGroupList', 'success').type:
      const groupNameDataSet = get(action.data, 'data', [])
      const groupedDataByGroupId = groupBy(groupNameDataSet, "groupId");
      let distinctRecords = Object.values(groupedDataByGroupId)?.map(records => ({
        ...records[0],
        groupName: records[0]?.groupName?.trim(),
        consolidatedGroupName: records[0]?.consolidatedGroupName?.trim(),
        clientId: records[0]?.clientId?.trim(),
        locationName: records[0]?.location?.trim() || "default",
         }));
      distinctRecords = distinctRecords.sort((a,b)=>new Date(b.updatedAt) - new Date(a.updatedAt))   
      const listByGroupName = groupBy(groupNameDataSet, "groupName");
      const consolidatedGroupNames = Object.keys(groupBy(groupNameDataSet, "consolidatedGroupName"));
      const groupListData = Object.keys(listByGroupName)
      return { ...state, groupListData, listByGroupName, consolidatedGroupNames, groupNameDataSet:distinctRecords };
    default:
      return state;
  }
}