import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const defaultClass = 'link';
export const HLink = ({ external, extraClassName, className, children, to, ...props }) => {
  if (extraClassName && className) throw new Error('extraClassName and className are mutually exclusive');
  className = className === undefined ? defaultClass : className;
  if (extraClassName) className += ` ${extraClassName}`;
  return external ? (
    <a href={to} className={className} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  ) : (
    <Link className={className} to={to} {...props}>
      {children}
    </Link>
  );
};

HLink.propTypes = {
  to: PropTypes.string,
  external: PropTypes.bool,
  extraClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.object
};
