import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ large, map, className, text = 'Loading...' }) => (
  <div className={`spinner ${map ?'map-overlay-spinner':'modal-overlay-spinner'}  ${large ? 'spinner-lg' : ''} ${className || ''}`} >
  <div className="spinner-lg-text">{text}</div>
  </div>
);

Spinner.propTypes = {
  large: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string
};
export default Spinner;
