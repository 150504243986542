import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";

import { H5, Button, IconButton } from "../index.js";

const ContentPopup = ({ title, content, closeModal, isOpen, isSubmit, onSubmit, disabled, className }) => {
  return (
    <Modal
      isOpen={isOpen}
      className={"modal-container " + className}
      overlayClassName="modal-overlay"
    >
      <IconButton
        icon="times"
        className="modal-close-btn"
        onClick={() => closeModal()}
      />
      <div className="ncss-col-sm-12 ">
        <H5 className="modal-text p2-bottom-sm">{title && title}</H5>
        {content && content}
      </div>
      <div className="ncss-col-sm-12 ">
      
      
        {isSubmit && (
          <div className="bottom-section ncss-col-sm-6 p4-top-sm popup-bottom ">
            <Button
            disabled={disabled}
              className="ncss-btn-secondary-dark u-bold secondary-button"
              onClick={() => onSubmit()}
            >
              SUBMIT
            </Button>
          </div>
        )}
        <div className="bottom-section ncss-col-sm-6 p4-top-sm popup-bottom">
          <Button
            className="ncss-btn-grey u-bold no-button button-width-adjust"
            onClick={() => closeModal()}
          >
            CANCEL
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ContentPopup.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.object,
  isSubmit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default ContentPopup;
