import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { displayMessage, getRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, endPointUrl, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    const res = yield call(getRequest, endPointUrl, action.data);
    // Display HTTP status error message

    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {

      const response = yield res.json();

      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);

      yield put(createServiceAction(serviceType, 'success')(response));
      if (!serverErrorMessage && response.statusCode !== 200) {
        const message = `${response.message}`;
        yield call(displayMessage, 'error', message, 5000);
      }
    }
    else {
      yield put(createServiceAction(serviceType, 'error')());
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* getUploadedFilesSagas() {
  yield takeEvery(createServiceAction("getUploadedFiles", 'requested').type, listener, "getUploadedFiles", apiConfig.getuploadedFiles.endPointUrl);
  yield takeEvery(createServiceAction("getUserFiles", 'requested').type, listener, "getUserFiles", apiConfig.getUserFiles.endPointUrl);
}

export default getUploadedFilesSagas;