import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    let options = {};
    options.email = action.data.email;
    options.userName = action.data.name || action.data.userName;
    options.contact = action.data.contact;
    options.groupClientID = action.data.groupClientID;
    if (action.data?.groupClientIDSA?.length !==0) options.groupClientIDSA = action.data.groupClientIDSA;
    options.vendors = action.data.vendors
    options.userType = action.data.userType
    options.materialAction = action.data.materialAction
    options.endpoint = action.data.endpoint
    
    const res = yield call(postRequest, apiConfig.addUser.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){  
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const message = `${response.message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
         // Dispatch action to  update User list
         yield put(createServiceAction('userDetails', 'requested')());
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* addUserSagas() {
  const serviceType = 'addUserProfile';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default addUserSagas;