import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { displayMessage, postRequest } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';
import { adminActions } from '../actions/authActions.js';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    const res = yield call(postRequest, apiConfig.submitIdLockerAccess.endPointUrl, action.data);
    // Display HTTP status error message

    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {

      const response = yield res.json();

      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')(response));
        yield call(displayMessage, 'success', response?.message, 5000);
        yield put(adminActions({ activeStep: 1, tabStatus: "REQUEST TRACKING", loading: false }))
      }
      else {
        yield put(adminActions({ loading: false }))
        yield put(createServiceAction(serviceType, 'error')())
        if (response?.statusCode !== 400 && response?.statusCode !== 200) {
          const message = response.message ? `${response.message}` : `${response.msg.message}`;
          yield call(displayMessage, 'error', message, 5000);
        }
      }
    }
    else {
      yield put(createServiceAction(serviceType, 'error')())
      yield put(adminActions({ loading: false }))
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* submitIdLockerUsersSagas() {
  const serviceType = 'submitIdLockerUsers';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default submitIdLockerUsersSagas;
