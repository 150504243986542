import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest } from '../util/httpRequest.js';
import { displayMessage } from '../util/messageHelper.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';
import { put, takeEvery, call } from 'redux-saga/effects';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import { selectedButton } from '../actions/authActions.js';


export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    let options = {};
    options.vendor_id = action.data.vendor_id;
    options.user_id = action.data.user_id;
    options.vendorIdentifier = action.data.vendorIdentifier ? action.data.vendorIdentifier : "";
    options.vendorGroupCode = action.data.vendorGroupCode ? action.data.vendorGroupCode : "";
    options.manufacturingLocationIdentifier = action.data.manufacturingLocationIdentifier ? action.data.manufacturingLocationIdentifier : "";
    options.name = action.data.vendorName ? action.data.vendorName : "";
    options.address1 = action.data.vendorAddress ? action.data.vendorAddress : "";
    options.address2 = action.data.mdoAddress2 ? action.data.mdoAddress2 : "";
    options.address3 = action.data.mdoAddress3 ? action.data.mdoAddress3 : "";
    options.area= action.data.mdoArea ? action.data.mdoArea : "";
    options.city = action.data.mdoCity ? action.data.mdoCity : "";
    options.state = action.data.mdoState ? action.data.mdoState :"";
    options.postal_code = action.data.mdoPostalCode ? action.data.mdoPostalCode : "";
    options.county = action.data.mdoCounty ? action.data.mdoCounty : "";
    options.country = action.data.mco ? action.data.mco : "";
    options.mdoVendorUpdate = action.data.mdoUpdate;
    options.comment = action.data.comments ? action.data.comments : "";
    options.type=action.data.type?action.data.type:"";
    const path = {
      history: null
    };
    const routes = pick(action.data, keys(path));


    const res = yield call(putRequest, apiConfig.vendorUpdate.endPointUrl, options);
    // Display HTTP status error message
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {
      const response = yield res.json();
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);
      if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const message = `${response.message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
        yield put(selectedButton('Vendor'))
        routes.history.push({
          pathname: "/mapping-data",
        });
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* updateVendorSagas() {
  const serviceType = 'updateVendor';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default updateVendorSagas;