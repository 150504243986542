import { fromJS } from 'immutable';
import get from 'lodash/get';

import { startAuthCheck, finishAuthCheck, setUserLogin, setUserLogout, checkUserExist, checkValidGroup, setAuthCheckError, selectedButton, cancelCalls, locationChange, vendorChange, columnChange } from '../actions/authActions.js';
import { createServiceAction } from '../util/actionHelper.js';

const defaultState = fromJS({
  hasAuthCheckStarted: false,
  hasAuthCheckFinished: false,
  isLoading: false,
  loginError: null,
  userToken: null,
  count: 200,
  matNextRequest: 0,
  token: null,
  loginAuthFinish: false,
  logoutUser: false,
  userExist: true,
  validGroup: true,
  getNextPage: -1,
  getTotalPage: 0,
  hasNextRequest: false,
  getTotalReq: -1,
  offset: "",
  limit: 5000,
  matLimit: 50,
  pageNo: 1,
  countPerDays: 0,
  buttonStatus: "Color",
  stopCall: false,
  matTotalRequests: 0,
  locChange: null,
  venChange: false,
  toalVendorRowCount: null,
  totalMaterialRowCount: null,
  totalColorRowCount: null,
  inProgress: false
});

export default function auth(state = defaultState, action) {
  state = fromJS(state); // Force immutable in case of rehydration
  switch (action.type) {
    case startAuthCheck.type:
      return state.set('hasAuthCheckStarted', true).set('hasAuthCheckFinished', false);
    case setAuthCheckError.type:
      return state.set('hasAuthCheckFinished', true).set('loginError', action.payload);
    case finishAuthCheck.type:
      return state.set('hasAuthCheckFinished', true);
    case setUserLogin.type:
      return state = state.setIn(['userToken'], action && action.payload && action.payload.accessToken ? fromJS(action.payload) : null)
        .setIn(['loginAuthFinish'], false)
        .setIn(['logoutUser'], action && action.payload && action.payload.accessToken ? false : true);
    case setUserLogout.type:
      return state.set('logoutUser', action.payload);
    case checkUserExist.type:
      return state.set('userExist', action.payload);
    case checkValidGroup.type:
      return state.set('validGroup', action.payload)

    case createServiceAction('getUserProfile', 'success').type:
      let temp = state.getIn(['userToken']);
      temp['userId'] = get(action.data, 'data.userId', '')
      temp['endpoint'] = get(action.data, 'data.endpoint', '')
      temp['savedUserType'] = get(action.data, 'data.userType', '')
      temp['supplierName'] = get(action.data, 'data.supplierName', '')
      temp['contact'] = get(action.data, 'data.contact', '')
      temp['userInactive'] = get(action.data, 'data.userInactive', false)
      temp['userName'] = get(action.data, 'data.userName', '')
      temp['groupName'] = get(action.data, "data.groupName", '')
      temp['consolidatedGroupName'] = get(action.data, "data.consolidatedGroupName", '')
      temp['materialAction'] = get(action.data, "data.materialAction", '')
      temp['suppliers'] = get(action.data, "data.suppliers", [])
      if (temp['userId'] === '') {
        return state = state.setIn(['loginAuthFinish'], false)
          .setIn(['hasAuthCheckFinished'], true)
          .setIn(['userToken'], temp)
      }
      return state = state.setIn(['loginAuthFinish'], true)
        .setIn(['hasAuthCheckFinished'], true)
        .setIn(['userToken'], temp)

    case createServiceAction('materialSearchByLocData', 'requested').type:
      return state.set('materialRequestCompleted', false)
        .set('hasNextRequest', false)
        .set('pageNo', 1)
        .set('offset', 0)
        .set('stopCall', false)
        .set('matTotalRequests', 0)
        .set('matNextRequest', 0)

    case createServiceAction('materialSearchByLocData', 'success').type:
      if (get(action.data, 'meta_info.currentRequest') !== undefined) {
        return state.set('matNextRequest', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10))
          .set('pageNo', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10) + 1)
          .set('hasNextRequest', get(action.data, 'meta_info.hasNextRequest'))
          .set('offset', parseInt(get(action.data, 'meta_info.offset', 0), 10) + parseInt(state.get('matLimit'), 10))
          .set('materialRequestCompleted', true)
          .set('matTotalRequests', parseInt(get(action.data, 'meta_info.totalRequests', 0), 10))
      }
      break;
    case createServiceAction('materialSearchByLocData', 'dataNotFound').type:
      return state.set('materialRequestCompleted', true);
    case createServiceAction('materialSearchByLocLazyLoad', 'success').type:
      if (get(action.data, 'meta_info.currentRequest') !== undefined) {
        return state.set('matNextRequest', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10))
          .set('pageNo', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10) + 1)
          .set('hasNextRequest', get(action.data, 'meta_info.hasNextRequest'))
          .set('offset', parseInt(get(action.data, 'meta_info.offset', 0), 10) + parseInt(state.get('matLimit'), 10))
          .set('materialRequestCompleted', true)
      }
      break;

    //po shipment lazy loading    
    case createServiceAction("getPOHeader", "requested").type:
      return state
        .set("hasNextRequest", false)
        .set("pageNo", 1)
        .set("offset", 0)
        .set("stopCall", false)
        .set("countPerDays",0);
    case createServiceAction("getPOHeader", "success").type:
      if (get(action.data, "meta_info.currentRequest") !== undefined) {
        return state
          .set("pageNo", parseInt(get(action.data, "meta_info.currentRequest", 0), 10) + 1)
          .set("hasNextRequest", get(action.data, "meta_info.hasNextRequest"))
          .set("offset", parseInt(get(action.data, "meta_info.offset", 0), 10) + parseInt(state.get("limit"), 10))
          .set("countPerDays", parseInt(get(action.data, "meta_info.countInRequest", 0), 10) + parseInt(get(action.data, "meta_info.offset", 0), 10));
      }
      return state;
    case createServiceAction("getPOHeaderLazyLoad", "success").type:
      if (get(action.data, "meta_info.currentRequest") !== undefined) {
        return state
          .set("pageNo", parseInt(get(action.data, "meta_info.currentRequest", 0), 10) + 1)
          .set("hasNextRequest", get(action.data, "meta_info.hasNextRequest"))
          .set("offset", parseInt(get(action.data, "meta_info.offset", 0), 10) + parseInt(state.get("limit"), 10))
          .set("countPerDays",parseInt(get(action.data, "meta_info.countInRequest", 0), 10) + parseInt(get(action.data, "meta_info.offset", 0), 10) );
      }
      return state;

    case createServiceAction('createUserProfile', 'success').type:
      return state.setIn(['userToken', 'userId'], get(action.data, 'data.userId', ''))
        .set('loginAuthFinish', false)
        .set('hasAuthCheckFinished', false);

    case selectedButton.type:
      return state.set('buttonStatus', action.payload)

    case cancelCalls.type:
      return state.set('stopCall', action.payload)

    case locationChange.type:
      return state.set('locChange', action.payload)

    case vendorChange.type:
      return state.set('venChange', action.payload)

    case columnChange.type:
      return state.set('gridColChange', action.payload)

    case createServiceAction('submitAPI', 'requested').type:
      return state.set('inProgress', true)

    case createServiceAction('submitAPI', 'success').type:
      return state.set('inProgress', false)

    case createServiceAction('submitAPI', 'error').type:
      return state.set('inProgress', false)

    case createServiceAction('userSubmitAPI', 'requested').type:
      return state.set('inProgress', true)

    case createServiceAction('userSubmitAPI', 'success').type:
      return state.set('inProgress', false)

    case createServiceAction('userSubmitAPI', 'error').type:
      return state.set('inProgress', false)

    case createServiceAction('colorGridData', 'requested').type:
      return state.set('hasNextRequest', false)
        .set('pageNo', 1)
        .set('offset', 0)
        .set('stopCall', false)
        .set('getTotalReq', -1)
        .set('initColChange', [])
        .set('filterChange', [])

    case createServiceAction('colorGridData', 'success').type:
      if (get(action.data, 'meta_info.currentRequest') !== undefined) {
        if (get(action.data, 'meta_info.totalCount') === 0) {
          return state.set('getNextPage', 0)
            .set('getTotalReq', 0)
        }
        else if (state.get("buttonStatus") === 'Color') {
          return state.set('getNextPage', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10))
            .set('pageNo', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10) + 1)
            .set('hasNextRequest', get(action.data, 'meta_info.hasNextRequest'))
            .set('offset', parseInt(get(action.data, 'meta_info.offset', 0), 10) + parseInt(state.get('limit'), 10))
            .set('getTotalReq', parseInt(get(action.data, 'meta_info.totalRequests', 0), 10))
            .set('initColChange', get(action.data, 'meta_info.columns'))
            .set('filterChange', get(action.data, 'meta_info.filters'))
            .set('totalColorRowCount', get(action.data, 'meta_info.totalCount'))
        }
      }
      return state.set('getNextPage', 0)
        .set('getTotalReq', 0);
    case createServiceAction('colorGridDataLazyLoad', 'success').type:
      if (get(action.data, 'meta_info.currentRequest') !== undefined) {
        return state.set('pageNo', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10) + 1)
          .set('hasNextRequest', get(action.data, 'meta_info.hasNextRequest'))
          .set('offset', parseInt(get(action.data, 'meta_info.offset', 0), 10) + parseInt(state.get('limit'), 10))
          .set('getNextPage', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10))
          .set('totalColorRowCount', get(action.data, 'meta_info.totalCount'))
      }
      return state.set('getNextPage', 0)
    case createServiceAction('vendorGridData', 'requested').type:
      return state.set('hasNextRequest', false)
        .set('pageNo', 1)
        .set('offset', 0)
        .set('stopCall', false)
        .set('getTotalReq', -1)
        .set('initColChange', [])
        .set('filterChange', [])

    case createServiceAction('vendorGridData', 'success').type:
      if (get(action.data, 'meta_info.currentRequest') !== undefined) {
        if (get(action.data, 'meta_info.totalCount') === 0) {
          return state.set('getNextPage', 0)
            .set('getTotalReq', 0)
        }
        else if (state.get("buttonStatus") === 'Vendor') {
          return state.set('getNextPage', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10))
            .set('pageNo', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10) + 1)
            .set('hasNextRequest', get(action.data, 'meta_info.hasNextRequest'))
            .set('offset', parseInt(get(action.data, 'meta_info.offset', 0), 10) + parseInt(state.get('limit'), 10))
            .set('getTotalReq', parseInt(get(action.data, 'meta_info.totalRequests', 0), 10))
            .set('initColChange', get(action.data, 'meta_info.columns'))
            .set('filterChange', get(action.data, 'meta_info.filters'))
            .set('totalVendorRowCount', get(action.data, 'meta_info.totalCount'))
        }
      }
      return state.set('getNextPage', 0)
    case createServiceAction('vendorGridDataLazyLoad', 'success').type:
      if (get(action.data, 'meta_info.currentRequest') !== undefined) {
        return state.set('pageNo', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10) + 1)
          .set('hasNextRequest', get(action.data, 'meta_info.hasNextRequest'))
          .set('offset', parseInt(get(action.data, 'meta_info.offset', 0), 10) + parseInt(state.get('limit'), 10))
          .set('getNextPage', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10))
          .set('totalVendorRowCount', get(action.data, 'meta_info.totalCount'))
      }
      return state.set('getNextPage', 0)
        .set('getTotalReq', 0)
    case createServiceAction('materialData', 'requested').type:
      return state.set('hasNextRequest', false)
        .set('pageNo', 1)
        .set('offset', 0)
        .set('stopCall', false)
        .set('getTotalReq', -1)
        .set('initColChange', [])
        .set('filterChange', [])

    case createServiceAction('materialData', 'success').type:
      if (get(action.data, 'meta_info.currentRequest') !== undefined) {
        if (get(action.data, 'meta_info.totalCount') === 0) {
          return state.set('getNextPage', 0)
            .set('getTotalReq', 0)
        }
        else if (state.get("buttonStatus") === 'Material') {
          return state.set('getNextPage', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10))
            .set('pageNo', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10) + 1)
            .set('hasNextRequest', get(action.data, 'meta_info.hasNextRequest'))
            .set('offset', parseInt(get(action.data, 'meta_info.offset', 0), 10) + parseInt(state.get('limit'), 10))
            .set('getTotalReq', parseInt(get(action.data, 'meta_info.totalRequests', 0), 10))
            .set('initColChange', get(action.data, 'meta_info.columns'))
            .set('filterChange', get(action.data, 'meta_info.filters'))
            .set('totalMaterialRowCount', get(action.data, 'meta_info.totalCount'))
        }
      }
      return state.set('getNextPage', 0)
        .set('getTotalReq', 0)
    case createServiceAction('materialDataLazyLoad', 'success').type:
      if (get(action.data, 'meta_info.currentRequest') !== undefined) {
        return state.set('pageNo', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10) + 1)
          .set('hasNextRequest', get(action.data, 'meta_info.hasNextRequest'))
          .set('offset', parseInt(get(action.data, 'meta_info.offset', 0), 10) + parseInt(state.get('limit'), 10))
          .set('getNextPage', parseInt(get(action.data, 'meta_info.currentRequest', 0), 10))
          .set('totalMaterialRowCount', get(action.data, 'meta_info.totalCount'))
      }
      return state.set('getNextPage', 0)
    default:
      return state;
  }
}

export const supplierName = state => state.auth.getIn(['userToken', 'supplierName']);
export const userLoggedIn = state => state.auth.get('userToken') !== null;
export const selectUserDetails = state => state.auth.get('userToken') ? state.auth.get('userToken') : {};
export const selectUserEndpoints = state => state.auth.get('userToken').endpoint;
export const selectUsername = state => state.auth.get('userToken').userName;
export const selectUserId = state => state.auth.get('userToken').userId;
export const selectEmail = state => state.auth.get('userToken').email;
export const selectColorRowCount = state => state.auth.get('totalColorRowCount');
export const selectMaterialRowCont = state => state.auth.get('totalMaterialRowCount');
export const selectVendorrowCount = state => state.auth.get('totalVendorRowCount');
export const selectFullName = state => state.auth.get('userToken') ? state.auth.get('userToken').fullName : '';
export const hasAuthCheckStarted = state => state.auth.get('hasAuthCheckStarted');
export const hasAuthCheckFinished = state => state.auth.get('hasAuthCheckFinished');
export const selectUserAccessToken = state => state.auth.get('userToken') ? state.auth.get('userToken').accessToken : null;
export const selectLoginError = state => state.auth.get('loginError');
export const selectGroup = state => state.auth.get('userToken')?.group;
export const getMaterialRequestCompleteStatus = state => state.auth.get('materialRequestCompleted');
export const selectVendorGroup = state => state.auth.get('userToken').groupName
export const selectConsolidatedGroupName = state => state.auth.get('userToken').consolidatedGroupName
export const selectMaterialAction = state => state.auth.get('userToken').materialAction
export const selectSuppliers = state => state.auth.get('userToken').suppliers

// identify groups(User group/type)
export const isPartner = state => state.auth.get('userToken').group === 'QA_partnerRole' || state.auth.get('userToken').group === 'partnerSME';
export const isMdoAdmin = state => state.auth.get('userToken').group === 'QA_adminRole' || state.auth.get('userToken').group === 'admin';
export const isVendorManager = state => state.auth.get('userToken').group === 'QA_vendorManagerRole' || state.auth.get('userToken').group === 'vendorManager';
export const isPartnerSA = state => state.auth.get('userToken').group === 'MaterialDataBulkImport' || state.auth.get('userToken').group === 'MaterialDataFileImport';;

export const loginAuthFinish = state => state.auth.get('loginAuthFinish');
export const selectGetTotalPage = state => state.auth.get('getTotalPage');
export const selectGetNextPage = state => state.auth.get('getNextPage');
export const getHasNextRequest = state => state.auth.get('hasNextRequest');
export const getOffset = state => state.auth.get('offset')
export const getPageNo = state => state.auth.get('pageNo')
export const getCountPerDays = (state) => state.auth.get("countPerDays")
export const getButtonStatus = state => state.auth.get('buttonStatus')
export const stopApiCalls = state => state.auth.get('stopCall')
export const getMaterialTotalRequests = state => state.auth.get('matTotalRequests')
export const getMaterialNextRequest = state => state.auth.get('matNextRequest')
export const getLocationChange = state => state.auth.get('locChange')
export const selectGetTotalReq = state => state.auth.get('getTotalReq')
export const getVendorChange = state => state.auth.get('venChange')
export const getColumnChange = state => state.auth.get('initColChange')
export const setColumnChange = state => state.auth.get('gridColChange')
export const getFilterChange = state => state.auth.get('filterChange')
export const getInProgress = state => state.auth.get('inProgress')

export const logout = state => state.auth.get('logoutUser');
export const validUser = state => state.auth.get('userExist');
export const validGroup = state => state.auth.get('validGroup')