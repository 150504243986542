import { toast } from 'react-toastify';

export function displayMessage(type, message, duration){
    const config =  {
        position: 'top-center',
        autoClose: duration,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
    };
    if(type === 'info'){
        toast.info(message, config);
    } else if(type === 'success'){
        toast.success(message, config);
    } else if(type === 'error'){
        toast.error(message, config);
    }
}