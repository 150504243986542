import { adminActions, clearModalData, uploadActions } from "../actions/authActions.js";
import { createServiceAction } from "../util/actionHelper.js";

const defaultState = {
  uploadObj: { "errorlen": 0, "params": {}, "endpoint": "", "groupName": "", "rowData": [], "colDef": null, "fileName": "", "loading": false },
  adminObj: {}
};

export default function uploadGrid(state = defaultState, action) {
  switch (action.type) {
    case uploadActions.type:
      return { ...state, uploadObj: { ...state.uploadObj, ...action.payload } };
    case adminActions.type:
      return { ...state, adminObj: { ...state.adminObj, ...action.payload } };
    case clearModalData.type:
      return { ...state, uploadObj: {}, adminObj: { ...state.adminObj, rowData: [], fileId: "", tabStatus: "PROVIDE ACCESS" } };
    case createServiceAction('submitAPI', 'success').type:
      return { ...state, uploadObj: { ...state.uploadObj, rowData: [], fileId: "" } }
    case createServiceAction('userSubmitAPI', 'success').type:
      return { ...state, adminObj: { ...state.adminObj, rowData: [], fileId: "" } }
    case createServiceAction('getUploadedFiles', 'requested').type:
      return { ...state, uploadObj: { ...state.uploadObj, loading: true } }
    case createServiceAction('getUploadedFiles', 'success').type:
      return { ...state, uploadObj: { ...state.uploadObj, loading: false } }
    case createServiceAction('getUploadedFiles', 'error').type:
      return { ...state, uploadObj: { ...state.uploadObj, loading: false } }
    default:
      return state;
  }
}