import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { H5, Button, IconButton } from '../index.js';

const ConfirmationBox =({ submitModal, submitValue, closeModal, message, buttonsDisplay, status, enabled })=> {
    return (
      <Modal isOpen={submitModal} className="modal-container" overlayClassName="modal-overlay">
        <IconButton icon="times" className="modal-close-btn" onClick={() => closeModal()} />
        <div className="ncss-col-sm-12 ">
          <H5 className="modal-text">{message}</H5>
        </div>
        {buttonsDisplay ? (
          <div className="ncss-col-sm-12 ">
            
            <div className="bottom-section ncss-col-sm-3 p4-top-sm popup-bottom">
              {!enabled && <Button
                className="ncss-btn-secondary-dark u-bold secondary-button button-width-adjust new-material"
                onClick={() => {
                  submitValue();
                  status===undefined && closeModal();
                }}
              >
                OK
              </Button>}
            </div>
            <div className="bottom-section ncss-col-sm-3 p4-top-sm popup-bottom">
              <Button
                className="ncss-btn-grey u-bold no-button button-width-adjust"
                onClick={() => closeModal()}
              >
                CANCEL
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    );
  
}
ConfirmationBox.propTypes = {
  submitModal: PropTypes.bool,
  submitValue: PropTypes.func,
  closeModal: PropTypes.func,
  message: PropTypes.string,
  buttonsDisplay: PropTypes.bool
};
ConfirmationBox.defaultProps = {
  buttonsDisplay: true
};
export default ConfirmationBox;
