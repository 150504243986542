import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';

const defaultState = {
    dashboardCount: [],
};

export default function getDashboardCountData(state = defaultState, action) {
    switch (action.type) {
        case createServiceAction("dashboardCountData", "requested").type:
            return state;
        case createServiceAction("dashboardCountData", "success").type:
          return { ...state, dashboardCount: get(action.data, 'data', [])};
        default:
            return state;
    }
}
