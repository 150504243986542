import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get';
import { clearModalData } from "../actions/authActions.js";

const defaultState = {
  colorGridData: [],
};

export default function colorGridData(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction('colorGridData', 'requested').type:
      return state;
    case createServiceAction('colorGridData', 'success').type:
      const gridData = get(action.data, 'data', [])
      const cData = gridData.map(data => {
        let colorObj = {}
        let date =new Date(data.lastSubmittedDate)
        let dd = date.getDate()
        if(dd<10){
          dd = '0' + dd
        }
        let mm = date.getMonth() + 1
        if(mm<10){
          mm = '0' + mm
        }
        colorObj.color_id = data.color_id
        colorObj.submittedID = data.catalog_item_id;
        colorObj.submittedItemDescription = data.catalog_desc;
        colorObj.vendorGroup = data.vendorGroup;
        colorObj.supplierName = data.vendorName;
        colorObj.colorID = data.id;
        colorObj.colorDescription = data.description;
        colorObj.colorName = data.name;
        colorObj.colorBrand = data.colorBrand;
        colorObj.lastsubmitteddatecolor = mm+"-"+dd+"-"+date.getFullYear();
        colorObj.endpointsourcecolor = data.endPointSource;
        colorObj.status = data.status===false?"Matching color record not found":null;
        colorObj.clientId = data.clientId;
        return colorObj;
      })
      return { ...state, colorGridData: cData };
      case createServiceAction("colorGridDataLazyLoad", "requested").type:
      return { ...state, colorGridData:[...state.colorGridData]};
      case createServiceAction("colorGridDataLazyLoad", "success").type:
        const lColorgridData = get(action.data, 'data', [])
        const lColorData = lColorgridData.map(data => {
          let colorObj = {}
          let date = new Date(data.lastSubmittedDate)
          let dd = date.getDate()
          if (dd < 10) {
            dd = '0' + dd
          }
          let mm = date.getMonth() + 1
          if (mm < 10) {
            mm = '0' + mm
          }
          colorObj.color_id = data.color_id
          colorObj.submittedID = data.catalog_item_id;
          colorObj.submittedItemDescription = data.catalog_desc;
          colorObj.vendorGroup = data.vendorGroup;
          colorObj.supplierName = data.vendorName;
          colorObj.colorID = data.id;
          colorObj.colorDescription = data.description;
          colorObj.colorName = data.name;
          colorObj.colorBrand = data.colorBrand;
          colorObj.lastsubmitteddatecolor = mm+"-"+dd+"-"+date.getFullYear();
          colorObj.endpointsourcecolor = data.endPointSource;
          colorObj.status = data.status === false ? "Matching color record not found" : null;
          colorObj.clientId = data.clientId;
          return colorObj;
        })
        return { ...state, colorGridData: [...state.colorGridData, ...lColorData] };
        case clearModalData.type:
          return { ...state, colorGridData: null};  
    default:
      return state;
  }
}