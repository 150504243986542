import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import parse from 'html-react-parser'

import { H5, IconButton } from '../../../components/index.js';
const  NotificationModal =({notifyModal, closeModal, message, notificationId})=> {
   
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return (
      <div>
        <Modal
           isOpen={notifyModal}
           onRequestClose={() => { closeModal(notificationId) }}
           className="modal-container notify-modal"
           overlayClassName="modal-overlay"
        >
          <IconButton icon="times" className="modal-close-btn" onClick={() => closeModal(notificationId)} />
            <div className="ncss-col-sm-12 ">
            {/* <H5 className="modal-text">{message}</H5> */}
            <H5 className="modal-text">{
                parse(message.replace(urlRegex, function (url) {
                            return ' <a href="' + url + '" target="_blank" class="notification-link">' + url + '</a>'
                         }))
                  }
            </H5>
            </div>
        </Modal>
      </div>
    );
  }
  
NotificationModal.propTypes = {
    notifyModal: PropTypes.bool,
    closeModal: PropTypes.func,
    message: PropTypes.string,

};
NotificationModal.defaultProps = {
    buttonsDisplay: true
};
export default NotificationModal;