import React, { Component } from 'react';
import { Container,H1 } from '../../components/index.js';

export class UserMismatchError extends Component {
  render(){
   return(
    <Container section className="user"> 
   <H1>
    <span role="img" aria-label=" Not Authorized">
      🙁
    </span>
    Assigned user role in OKTA and MDO application is different. Please contact MDO Support.
  </H1>
  </Container>);
  }
}
 
export default  UserMismatchError;
