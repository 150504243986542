import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import NotificationModal from '../notificationModal/notificationModal.js';
import { mergeArrays } from '../../../util/index.js';
import { createServiceAction } from '../../../util/actionHelper.js';

const NotificationArea = ({notifications, isMdoAdmin, userGroup}) =>{
  
    const [notifyModal, setNotifyModal] = useState(false);
    const [message, setMessage] = useState('');
    const [ notificationId, setNotificationId] = useState(null);
    const dispatch = useDispatch();
    const notifyModalOpen = (modalopen, message, notificationId) => {
       // this.setState({ notifyModal: modalopen, message, notificationId });
       setNotifyModal(modalopen)
       setMessage(message)
       setNotificationId(notificationId)
    };
    function  closeNotifyModal(notificationId) {

        if (notificationId) {
            const id = []
            id.push(notificationId)
            dispatch(createServiceAction('readNotification', 'submitted')(id));
        }
        setNotifyModal(false)
    };

    //render() {
       // const { notifications, isMdoAdmin, userGroup } = this.props;
        //const { notifyModal, message, notificationId } = this.state;
        let uniqueGroups = []
        if (isMdoAdmin) {
            const allGroups = notifications && notifications.map(e => e.userGroups)
            uniqueGroups = mergeArrays(allGroups)
        }
        return (
            <span>
               
                    <span>
                        <div className='notification-head'>NOTIFICATIONS</div>
                        {(notifications && notifications.length > 0) ?
                            notifications.map((notification) =>
                                (
                                    <span >
                                        {
                                            isMdoAdmin ?
                                                <span>
                                                    {notification.userGroups.includes('admin') &&
                                                        new Date(moment(notification.startDate).utc().format('DD-MMM-YYYY')) <= new Date()
                                                        && new Date(moment(notification.endDate).utc().format('DD-MMM-YYYY')) >= new Date() ?


                                                        <li className={` custom-notification-section ${!notification.isRead ? 'unread-line' : ''}`}>

                                                            <div className="ncss-col-sm-9 notification-message">
                                                                <i class="fas fa-bullhorn"> </i>
                                                                <span className='text-notify'>
                                                                    {notification.message.length > 99 ? notification.message.substr(0, 100) + '....' : notification.message}
                                                                </span>
                                                            </div>
                                                            <div className="ncss-col-sm-2 link-text"
                                                                onClick={ notifyModalOpen(true, notification.message, notification.id)}>Click Here for More Details</div>
                                                            {/* <div className="ncss-col-sm-1">
                          <span className='link-text-close'>CLOSE <i class="fas fa-times"></i></span>
                        </div> */}
                                                        </li >
                                                        :
                                                        <span>
                                                            {isMdoAdmin && !uniqueGroups.includes(userGroup) ?
                                                                <div className="notification-message align-center">

                                                                    <span className='text-notify-no'>
                                                                        No notifications found
                                                         </span>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </span>

                                                    }

                                                </span>
                                                :
                                                <li className={` custom-notification-section ${!notification.isRead ? 'unread-line' : ''}`}>
                                                    <div className="ncss-col-sm-9 notification-message">
                                                        <i class="fas fa-bullhorn"> </i>
                                                        <span className='text-notify'>
                                                            {notification.message.length > 99 ? notification.message.substr(0, 100) + '....' : notification.message}
                                                        </span>
                                                    </div>
                                                    <div className="ncss-col-sm-2 link-text"
                                                        onClick={ notifyModalOpen(true, notification.message, notification.id)}>Click Here for More Details</div>
                                                    {/* <div className="ncss-col-sm-1">
                          <span className='link-text-close'>CLOSE <i class="fas fa-times"></i></span>
                        </div> */}
                                                </li >

                                        }
                                    </span>
                                )
                            ) :
                            <div className="notification-message align-center">

                                <span className='text-notify-no'>
                                    No notifications found
                        </span>
                            </div>
                        }
                        <span>
                            {isMdoAdmin && !uniqueGroups.includes(userGroup) ?
                                <div className="notification-message align-center">

                                    <span className='text-notify-no'>

                                    </span>
                                </div>
                                :
                                null
                            }

                        </span>
                        <NotificationModal message={message} notificationId={notificationId} notifyModal={notifyModal} closeModal={closeNotifyModal} />
                    </span>
                
            </span>
        )
    //}
}
NotificationArea.propTypes = {
    notifications: PropTypes.array
};


export default NotificationArea;

