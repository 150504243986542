import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { postRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call } from 'redux-saga/effects';

export function* listener(serviceType, endPointUrl, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());

    const res = yield call(postRequest, endPointUrl, action.data);
    // Display HTTP status error message

    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if (!statusErrorMessage) {

      const response = yield res.json();

      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);

      if (!serverErrorMessage && response.statusCode === 201) {
        yield put(createServiceAction(serviceType, 'success')());
        const message = "File has been submitted and is being processed. Please check the \"View Submitted Tab\" for the status";
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
      }
      if (!serverErrorMessage && response.statusCode !== 201) {
        yield put(createServiceAction(serviceType, 'error')());
        const message = response.message ? `${response.message}` : `${response.errors[0].code}`;
        // Display error message
        yield call(displayMessage, 'error', message, 5000);
      }
    }
    else {
      yield put(createServiceAction(serviceType, 'error')());
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* submitUploadSagas() {
  yield takeEvery(createServiceAction('submitAPI', 'requested').type, listener, 'submitAPI', apiConfig.submitUpload.endPointUrl);
  yield takeEvery(createServiceAction('userSubmitAPI', 'requested').type, listener, 'userSubmitAPI', apiConfig.submitUser.endPointUrl)
}

export default submitUploadSagas;