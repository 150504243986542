import { createServiceAction } from '../util/actionHelper.js';
import { apiConfig } from '../configs/apiConfig.js';
import { putRequest, displayMessage } from '../util/index.js';
import { getHTTPStatusValidationMessage, getServerValidationMessage } from '../services/serverValidations.js';

import { put, takeEvery, call} from 'redux-saga/effects';

export function* listener(serviceType, action) {
  try {
    yield put(createServiceAction(serviceType, 'loading')());
    let params = {};
    params.email = action.data.email;
    params.userName = action.data.name;
    params.userType = action.data.userType === 'PARTNER'?"partnerSME"
    :action.data.userType === 'VENDOR-MANAGER'?"vendorManager":action.data.userType === 'PARTNER(SA)'?"MaterialDataFileImport":"";
    params.isActive = action.data.activeStatus;
    params.profileUpdated = true;
    if (action?.data?.groupClientID?.length !==0) params.groupClientID = action?.data?.groupClientID;
    if (action?.data?.groupClientIDSA?.length !==0) params.groupClientIDSA = action?.data?.groupClientIDSA;
    params.contact = action.data.contact;
    params.vendors = action.data.vendors;
    params.endpoint = action.data.endpoint;
    params.materialAction = action.data.materialAction

    let queryParams = {};
    queryParams.userId = action.data.userId
    
    const res = yield call(putRequest, apiConfig.updateUser.endPointUrl, params, queryParams);
    // Display HTTP status error message
    
    const statusErrorMessage = yield call(getHTTPStatusValidationMessage, res);
    if(!statusErrorMessage){ 
      
      const response = yield res.json();
     
      // Display server error message
      const serverErrorMessage = yield call(getServerValidationMessage, response);     
       
       if (!serverErrorMessage && response.statusCode === 200) {
        yield put(createServiceAction(serviceType, 'success')(response));
        const message = `${response.message}`;
        // Display success message
        yield call(displayMessage, 'success', message, 5000);
    //      // Dispatch action to  update User list
         yield put(createServiceAction('userDetails', 'requested')());
      }
    }
  } catch (e) {
    yield put(createServiceAction(serviceType, 'error')(e));
  }
}

function* updateUserSagas() {
  const serviceType = 'updateUser';
  yield takeEvery(createServiceAction(serviceType, 'requested').type, listener, serviceType);
}

export default updateUserSagas;
