import { createServiceAction } from "../util/actionHelper.js";
import get from 'lodash/get'
import { clearModalData, materialReinitilized } from "../actions/authActions.js";

const defaultState = {
  materialSearchByLoc: null,
  matLocation: null,
  matLoc: null
};

export default function getMaterialSearchData(state = defaultState, action) {
  switch (action.type) {
    case createServiceAction("materialSearchByLocData", "requested").type:
      return { ...state, matLocation: action.data };
    case createServiceAction("materialSearchByLocData", "success").type:
      const materialGridData = get(action.data, 'data', [])
      const materialData = materialGridData.map(data => {
        let materialObj = {}
        materialObj.pcxMaterialID = data.id;
        materialObj.nikeMaterialID = data.suppliedMaterialId;
        materialObj.pdmNumber = data.pdmId;
        materialObj.ppsNumber = data.ppsId;
        materialObj.nikeMaterialDescription = data.description;
        materialObj.pcxsuppliedmaterialname = data.suppliedMaterialItemName;
        materialObj.pdmsuppliedmaterialname = data.materialName;
        materialObj.pcxSupplierLocationID= state.matLocation
        return materialObj;
      })
      return { ...state, materialSearchByLoc: materialData }
    case materialReinitilized.type:
      return { ...state, materialSearchByLoc: null }
    case createServiceAction("materialSearchByLocLazyLoad", "requested").type:
      return { ...state, materialSearchByLoc: [...state.materialSearchByLoc], matLoc: action.data };
    case createServiceAction("materialSearchByLocLazyLoad", "success").type:
      const lmaterialSearchByLoc = get(action.data, 'data', [])
      const lMaterialData = lmaterialSearchByLoc.map(data => {
        let materialObj = {}
        materialObj.pcxMaterialID = data.id;
        materialObj.nikeMaterialID = data.suppliedMaterialId;
        materialObj.pdmNumber = data.pdmId;
        materialObj.ppsNumber = data.ppsId;
        materialObj.nikeMaterialDescription = data.description;
        materialObj.pcxsuppliedmaterialname = data.suppliedMaterialItemName;
        materialObj.pdmsuppliedmaterialname = data.materialName;
        materialObj.pcxSupplierLocationID= state.matLocation
        return materialObj;
      })
      if (state.matLocation === state.matLoc && state.materialSearchByLoc !== null) {
        return { ...state, materialSearchByLoc: [...state.materialSearchByLoc, ...lMaterialData] };
      }
      else {
        return { ...state, materialSearchByLoc: [...state.materialSearchByLoc] };
      }
    case clearModalData.type:
      return { ...state, materialSearchByLoc: null }
    default:
      return state;

  }
}
